<template>
  <div class="relative h-full guest-layout" :class="$route.name === 'register.native' || bg ? 'bg-onboarding_gray' : 'bg-white'">
    <main :class="`relative h-full mx-auto ${$route.path === '/' ? '' : 'max-w-5xl'}`">
      <router-view v-if="$route.path === '/login' || $route.path === '/register'" @register-select="handleBG" />
      <router-view v-else/>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      bg: false
    }
  },
  computed: {
    ...mapState({
      user: ({ session }) => session.user
    }),
    ...mapGetters({
      lang: 'lang'
    }),
    bgVerifier () {
      console.log(this.$router)
      // if (this.bg) {
      //   return true
      // }
      return false
    }
  },
  methods: {
    ...mapActions({
      logout: 'session/logout'
    }),
    handleBG (e) {
      this.bg = e
    }
  }
}
</script>
