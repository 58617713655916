<template>
  <div class="flex flex-col justify-center h-full space-y-8 px-4">
    <div>
      <h1 class="text-xl text-dgray">
        Tu solicitud no fue aceptada por que tu cuenta no cumple con los requerimientos establecidos.
      </h1>
    </div>
    <div>
      <p class="text-sm">info@solofoodies.com</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'failed',
  props: {
    user: { required: false, type: Object }
  }
}
</script>

<style scoped>

</style>
