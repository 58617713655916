export default {
  namespaced: true,
  state: {
    all: [],
    pagination: { data: [] },
    current: undefined,
    back: false,
    loading: {
      index: false,
      show: false,
      store: false,
      update: false,
      delete: false
    }
  },
  mutations: {
    SET_ALL (state, items) {
      return (state.all = items)
    },
    SET_PAGINATION (state, pagination) {
      return (state.pagination = pagination)
    },
    SET (state, item) {
      return (state.current = item)
    },
    TOGGLE_LOADING (state, type) {
      return (state.loading[type] = !state.loading[type])
    },
    TOGGLE_RETURN (state, back) {
      state.back = back
      return state.back
    }
  },
  actions: {
    index ({ commit }, query) {
      commit('TOGGLE_LOADING', 'index')
      return this.$repository.foodies
        .index(query)
        .then(({ data }) => {
          commit('SET_PAGINATION', data)
          return data
        })
        .finally(() => {
          commit('TOGGLE_LOADING', 'index')
        })
    },
    show ({ commit }, id) {
      commit('TOGGLE_LOADING', 'show')
      return this.$repository.foodies
        .show(id, { with_count: 'completedCollabs' }, { cancelable: false })
        .then(({ data }) => {
          commit('SET', data)
          return data
        })
        .finally(() => {
          const back = true
          commit('TOGGLE_LOADING', 'show')
          commit('TOGGLE_RETURN', back)
        })
    },
    store ({ commit }, body) {
      commit('TOGGLE_LOADING', 'store')
      return this.$repository.foodies
        .store(body)
        .then(({ data }) => {
          this.$router.push({ name: 'Explore' })
          return data
        })
        .finally(() => commit('TOGGLE_LOADING', 'store'))
    }
  },
  getters: {
    isPremium: (state) => (state.current?.is_premium)
  }
}
