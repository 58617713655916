export default (date) => {
  const dateValue = new Date(date)
  // console.log(date)
  // console.log(dateValue.toUTCString())
  // const formatValue = (time) => {
  //   if (time < 10) {
  //     return `0${time}`
  //   }
  //   return time
  // }
  // const formatDate = (date) => {
  //   if (date < 10) {
  //     return `0${date}`
  //   }
  //   return date
  // }
  const dateItems = {
    date: {
      DD: '',
      MM: '',
      YY: '',
      hh: '',
      mm: ''
    }
  }
  // const dateFormatted = {
  //   date: {}
  // }
  // dateItems.date.DD = new Date(dateValue.toUTCString()).getDate()
  dateItems.date.DD = dateValue.getUTCDate().toString()
  dateItems.date.MM = dateValue.getUTCMonth().toString()
  dateItems.date.YY = dateValue.getUTCFullYear().toString()
  dateItems.date.hh = dateValue.getUTCHours().toString()
  dateItems.date.mm = dateValue.getUTCMinutes().toString()

  return dateItems
}
