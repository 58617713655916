import { OnlyPublic } from '@/router/middlewares'
// import Welcome from '@/views/public/Welcome'
// import Politics from '@/views/public/Politics'
// import cookiesPolitics from '@/views/public/cookies-politics'
// import Terms from '@/views/public/Terms'
// import Login from '@/views/public/Login'
// import Welcome from '@/views/public/Welcome'
// import PasswordReset from '@/views/public/PasswordReset'
// import ConfirmPasswordReset from '@/views/public/ConfirmPasswordReset'
// import NotFound from '@/views/404'
// import Register from '@/views/public/Register'
// import Invitation from '@/views/public/register/Invitation'
// import Mock from '@/views/Mock'
// import SoloLink from '@/views/public/sololink/index'
// import Reservation from '@/views/public/sololink/reservation'
// import RegisterFoodie from '@/views/public/register/Foodie'
// import RegisterRestaurant from '@/views/public/register/Restaurant'

export default [
  {
    path: '/',
    name: 'Welcome',
    component: () => import('../../views/public/Welcome'),
    meta: {
      middleware: OnlyPublic
    }
  },
  {
    path: '/politics',
    name: 'Politics',
    component: () => import('../../views/public/Politics')
  },
  {
    path: '/cookies-politics',
    name: 'cookies-politics',
    component: () => import('../../views/public/cookies-politics')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../../views/public/Terms')
  },
  {
    path: '/login',
    alias: '/register',
    name: 'Login',
    component: () => import('../../views/public/Login'),
    meta: {
      middleware: OnlyPublic
    }
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: () => import('../../views/public/PasswordReset'),
    meta: {
      middleware: OnlyPublic
    }
  },
  {
    path: '/confirm-password-reset',
    name: 'ConfirmPasswordReset',
    component: () => import('../../views/public/ConfirmPasswordReset'),
    meta: {
      middleware: OnlyPublic
    }
  },
  // {
  //   path: '/onboarding',
  //   name: 'register',
  //   component: () => import('../../views/public/Register'),
  //   meta: {
  //     middleware: [OnboardingSession]
  //   }
  // },
  {
    path: '/register/invitation',
    name: 'register.invitation',
    component: () => import('../../views/public/register/Invitation')
  },
  {
    path: '/register/:usertype',
    name: 'register.native',
    component: () => import('../../views/public/Register')
  },
  {
    path: '/mock',
    name: 'mock',
    component: () => import('../../views/Mock')
  },
  // {
  //   path: '/:igusername',
  //   name: 'sololink.index',
  //   component: () => import('../../views/public/sololink/index')
  // },
  // {
  //   path: '/:igusername/:uuid',
  //   name: 'sololink.reservation',
  //   component: () => import('../../views/public/sololink/reservation'),
  //   meta: {
  //     middleware: OnlyPublic
  //   }
  // },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../../views/public/Login'),
    meta: {
      middleware: OnlyPublic
    }
  }
]
