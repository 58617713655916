export default {
  namespaced: true,
  state: {
    all: [],
    pagination: { data: [] },
    current: undefined,
    loading: {
      index: false,
      show: false,
      store: false,
      update: false,
      delete: false
    }
  },
  mutations: {
    SET_ALL (state, items) {
      return (state.all = items)
    },
    SET_PAGINATION (state, pagination) {
      return (state.pagination = pagination)
    },
    SET (state, item) {
      return (state.current = item)
    },
    TOGGLE_LOADING (state, type) {
      return (state.loading[type] = !state.loading[type])
    },
    TOGGLE_RETURN (state, back) {
      state.back = back
      console.log(state.back)
      return (state.back = back)
    }
  },
  actions: {
    index ({ commit }, query) {
      commit('TOGGLE_LOADING', 'index')
      return this.$repository.restaurants
        .index(query)
        .then(({ data }) => {
          commit('SET_PAGINATION', data)
          return data
        })
        .finally(() => commit('TOGGLE_LOADING', 'index'))
    },
    show ({ commit }, id) {
      commit('TOGGLE_LOADING', 'show')
      return this.$repository.restaurants
        .show(id)
        .then(({ data }) => {
          commit('SET', data)
          return data
        })
        .finally(() => commit('TOGGLE_LOADING', 'show'))
    },
    store ({ commit }, body) {
      commit('TOGGLE_LOADING', 'store')
      return this.$repository.restaurants
        .store(body)
        .then(({ data }) => {
          this.$router.push({ name: 'Explore' })
          return data
        })
        .finally(() => commit('TOGGLE_LOADING', 'store'))
    },
    update ({ commit }, { id, body }) {
      commit('TOGGLE_LOADING', 'update')
      return this.$repository.restaurants
        .update(id, body)
        .then(({ data }) => data)
        .finally(() => commit('TOGGLE_LOADING', 'update'))
    }
    // setFavorite ({ commit }, id, body) {
    //   return this.$repository.restaurants
    //     .setFavorite(id, body)
    //     .then(({ data }) => {
    //       return data
    //     })
    // }
  }
}
