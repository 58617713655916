// import { accountService } from '@/_services';

const facebookAppId = process.env.VUE_APP_NODE_ENV === 'dev-https'
  ? process.env.VUE_APP_DEV_FACEBOOK_APP_ID
  : process.env.VUE_APP_FACEBOOK_APP_ID

const initialiceFacebookSDK = () => {
  return new Promise(resolve => {
    window.fbAsyncInit = () => {
      const FB = window.FB

      FB.init({
        appId: facebookAppId,
        cookie: true,
        xfbml: true,
        version: 'v10.0'
      })

      // auto authenticate with the api if already logged in with facebook
      FB.getLoginStatus(({ authResponse }) => {
        resolve(authResponse)
      })
    };

    // load facebook sdk script
    (function (doc, script, id) {
      const facebookScript = doc.getElementsByTagName(script)[0]

      if (doc.getElementById(id)) {
        return
      }

      const js = doc.createElement(script)
      js.id = id
      js.src = 'https://connect.facebook.net/es_ES/sdk.js'
      facebookScript.parentNode.insertBefore(js, facebookScript)
    }(document, 'script', 'facebook-jssdk'))
  })
}

export default initialiceFacebookSDK
