<template>
  <a-loader  v-if="!restaurant" class="h-12 w-12 block mx-auto" :color="'text-gray-400'"/>
  <a-stepper v-else :slots="3" class="h-screen flex flex-col overflow-auto stepper-padding pb-24">

    <template #step-1>
      <information
          v-model:name="user.name"
          v-model:ig_username="user.ig_username"
          v-model:contact_name="user.contact_name"
          v-model:phone="user.phone"
          :confirmation="confirmation"
          @loading="loading = $event"
          @should-disable-next-igusername="shouldDisableNextIgUsername = $event"
          @should-disable-next-code="shouldDisableNextCode = $event.value"
          class="flex-grow flex items-center"/>
    </template>

    <template #step-2>
      <Address
          v-model:address="address.address"
          v-model:city="address.city"
          v-model:state="address.state"
          v-model:zip_code="address.zip_code"
          :confirmation="confirmation"
          class="flex-grow flex items-center"/>
    </template>

    <template #step-3>
      <BillingAddress
          v-model:cif="user.cif"
          v-model:business_name="billingAddress.business_name"
          v-model:billing_cif="billingAddress.cif"
          v-model:billing_address="billingAddress.address_line"
          v-model:billing_city="billingAddress.city"
          v-model:billing_state="billingAddress.state"
          v-model:billing_zip_code="billingAddress.zip_code"
          v-model:same_address="sameAddress"
          :address="{ address: address.address, city: address.city, state: address.state, zip_code: address.zip_code }"
          :confirmation="confirmation"
          class="flex-grow flex items-center"/>
    </template>

    <template #footer="{ index, goPrev, goNext }">
      <div class="fixed inset-x-0 container mx-auto bottom-0 max-w-5xl">
        <div class="flex justify-between py-4 px-8 mb-14">
          <button
              class="bg-onboarding_gray focus:outline-none flex justify-center p-2 rounded-full uppercase disabled:bg-onboarding_light_gray transition-all ease-in-out duration-300"
              :disabled="loading"
              v-if="index > 1"
              @click="goPrev"
          >
            <button class="rounded-full h-10 w-10 text-white">
              <i class="icon-arrowLeft"></i>
            </button>
          </button>
          <div v-else/>

          <button
              class="bg-onboarding_gray focus:outline-none flex justify-center p-2 rounded-full uppercase disabled:bg-onboarding_light_gray transition-all ease-in-out duration-300"
              :disabled="disableNext(index)"
              v-if="index <= 3"
              @click="update(index, goNext)"
          >
            <a-loader v-if="loading" color="text-white"
                      class="h-10 w-10"/>
            <button v-else class="rounded-full h-10 w-10 text-white">
              <i class="icon-arrowRight"></i>
            </button>
          </button>
          <div v-else/>
        </div>
      </div>
    </template>
  </a-stepper>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import { useRepository } from '../../../hooks'
import router from '@/router'
import Address from './address'
import BillingAddress from './billing-address'
import Information from './information'

export default {
  name: 'index',
  components: {
    Address,
    BillingAddress,
    Information
  },
  props: {
    restaurant: { required: true, type: Object }
  },
  setup (props) {
    const user = ref({})
    const restaurantRepository = useRepository(({ restaurants }) => restaurants)
    // const session = useSession()
    const shouldDisableNextIgUsername = ref(false)
    const shouldDisableNextCode = ref(false)
    const confirmation = ref(false)
    const loading = ref(false)
    const sameAddress = ref(false)
    const address = ref({
      address: '',
      city: '',
      state: '',
      zip_code: ''
    })
    const billingAddress = ref({
      cif: '',
      address_line: '',
      city: '',
      state: '',
      zip_code: '',
      business_name: ''
    })

    const getPhoneLength = computed(() => {
      return user.value.phone?.length
    })

    const disableNext = (index) => {
      // console.log(shouldDisableNextIgUsername)
      // return loading.value || !user.value.ig_username || (user.value.ig_username.length < 3) || shouldDisableNextIgUsername
      return loading.value || shouldDisableNextIgUsername.value || shouldDisableNextCode.value ||
          ((!user.value.ig_username || user.value.ig_username.length < 3 || getPhoneLength.value < 9 || !user.value.contact_name || !user.value.name || confirmation.value) && index === 1) ||
          ((!address.value.address || !address.value.city || !address.value.zip_code) && index === 2) ||
          ((!billingAddress.value.address_line || !billingAddress.value.city || !billingAddress.value.zip_code || !user.value.cif || user.value.cif.length < 9 || !billingAddress.value.business_name) && index === 3)
    }

    const update = (index, goNext) => {
      switch (index) {
        case 3: {
          updateUser()
          break
        }
        default: { goNext() }
      }
    }
    const updateUser = () => {
      loading.value = true
      const body = {
        name: user.value.name,
        ig_username: user.value.ig_username,
        cif: user.value.cif,
        contact_name: user.value.contact_name,
        phone: '+34' + user.value.phone,
        address: address.value.address,
        city: address.value.city,
        state: address.value.state,
        zip_code: address.value.zip_code,
        billing_address: billingAddress.value
      }
      restaurantRepository.update(props.restaurant.id, body)
        .then(() => {
          router.go()
          // console.log(res)
        })
        .finally(() => (loading.value = false))
    }

    onMounted(() => {
      user.value = { ...props.restaurant, cif: user?.value?.billing_address?.cif, phone: props.restaurant.phone.replace('+34', '') }
      address.value = {
        address: user.value.address,
        city: user.value.city,
        state: user.value.state,
        zip_code: user.value.zip_code
      }
      billingAddress.value = {
        cif: user?.value?.billing_address?.cif,
        address_line: user?.value?.billing_address?.address_line,
        city: user?.value?.billing_address?.city,
        state: user?.value?.billing_address?.state,
        zip_code: user?.value?.billing_address?.zip_code,
        business_name: user?.value?.billing_address?.business_name
      }
    })

    return {
      user,
      confirmation,
      loading,
      address,
      billingAddress,
      sameAddress,
      getPhoneLength,
      shouldDisableNextIgUsername,
      shouldDisableNextCode,
      updateUser,
      disableNext,
      update
    }
  }
}
</script>

<style scoped>

</style>
