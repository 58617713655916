<template>
  <div>
    <div class="bg-gray-200 rounded-lg p-1">
      <div class="relative flex items-center">

        <!-- tab dividers -->
<!--        <div class="absolute w-full">-->
<!--          <div class="flex justify-between m-auto" :class="`w-1/${segment}`" v-for="segment in segments.length - 1" :key="segment" />-->
<!--          <div class="h-3 w-px bg-gray-400 rounded-full opacity-0 transition-opacity duration-100 ease-in-out" :class="{ 'opacity-100': selectedIndex === 2}" />-->
<!--          <div class="h-3 w-px bg-gray-400 rounded-full opacity-0 transition-opacity duration-100 ease-in-out" :class="{ 'opacity-100': selectedIndex === 2}" />-->
<!--        </div>-->

        <!-- white sliding tab block -->
        <div :class="`w-1/${segments.length}`" class="absolute left-0 inset-y-0 flex bg-white transition-all ease-in-out duration-200 transform rounded-md shadow" :style="{
          transform: `translateX(${selectedIndex * 100}%)`
        }" />

        <!-- clickable buttons -->
        <div class="w-full flex items-center">
          <button class="relative flex-1 flex text-sm font-semibold capitalize items-center justify-center cursor-pointer m-px p-px" @click="selectedIndex = key" v-for="(segment, key) in segments" :key="key">
            <slot :name="`segment-${key}`">
              {{ segment }}
            </slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    selectedIndex: 0
  }),
  props: {
    segments: { required: false, type: Array, default: () => [] }
  },
  watch: {
    selectedIndex (val) {
      this.$emit('change', val)
    }
  }
}
</script>

<style scoped>

</style>
