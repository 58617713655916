<template>
  <div class="relative h-full guest-layout">
    <!--    SIDE BAR -->
    <aside class="bg-black bg-opacity-50 inset-0 z-40">

      <transition name="fade">
        <div @click="handleSidebar" class="fixed inset-0 bg-black z-40 bg-opacity-50" v-if="showSidebar" />
      </transition>

      <div class="flex flex-col w-12 fixed w-56 bg-white right-0 z-40 inset-y-0 transition-transform transform" :class="{
          'translate-x-56': !showSidebar,
          'translate-x-0': showSidebar
        }">
        <div class="p-normal text-left sidebar-i-container border-b border-gray">
          <div>
            <div>
              <img src="../../assets/logo.png" class="w-base h-base object-cover rounded-full" alt="profile picture"/>
            </div>
            <p class="text-lg font-regular pt-extra">{{ this.isAuthenticated ? user.foodie.name : '' }}</p>
          </div>
        </div>

        <div class="py-2">
        </div>

        <div class="flex-grow pt-small">
        </div>
        <div>
          <a-logo-switch type="1" class="w-40 select-none lowercase inline-block"/>
        </div>
        <div class="w-full bottom-0 border-t border-gray">
<!--          <button @click="destroySessionWithUser" class="cursor-pointer text-red p-3 w-full text-left">-->
<!--            <i class="icon-logout text-xxl pr-sm align-middle" />-->
<!--            DESTRUIR SESIÓN-->
<!--          </button>-->
          <button @click="(adviced = !adviced) && (showSidebar = false)" class="cursor-pointer p-3 w-full text-left">
            <i class="icon-logout text-xxl pr-sm align-middle" />
            Cerrar sesión
          </button>
        </div>
      </div>
    </aside>
    <main class="h-full lg:pt-14 max-w-5xl mx-auto">
      <router-view></router-view>
    </main>

    <nav class="fixed inset-x-0 container mx-auto bottom-0 lg:top-0 lg:bottom-auto border-t lg:border-t-0 max-w-5xl lg:border-b bg-white">
      <div class="grid grid-cols-4 gap-4 text-xxl border-gray font-light py-sm">
        <div class="text-gray cursor-pointer text-right">
        </div>
        <div class="text-gray cursor-pointer text-right">
        </div>
        <div class="text-gray cursor-pointer text-right">
        </div>
        <div class="text-gray cursor-pointer text-center" @click="handleSidebar">
          <i class="icon-profile " />
        </div>
      </div>
    </nav>
  </div>

  <a-alert :loading="loading" :show="adviced" @confirm="confirmLogout" @cancel="adviced = !adviced">
    <div class="text-left space-y-4">
      <h1 class="text-lg font-semibold text-center">¿Deseas cerrar la sesión?</h1>
    </div>

    <template #confirm>
      <a-loader color="text-primary" class="h-8 w-8" v-if="loading" />
      <template v-else>
        Aceptar
      </template>
    </template>
  </a-alert>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  // props: {
  //   restaurant: { required: true, type: Object }
  // },
  data () {
    return {
      connected: 'undefined',
      fbError: false,
      FBCError: null,
      sidebar: true,
      responsiveMenu: false,
      showSidebar: false,
      adviced: false,
      loading: false,
      profile: {}
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    })
  },
  methods: {
    ...mapActions({
      facebookConnect: 'session/facebookConnect',
      logout: 'session/logout',
      clear: 'clear'
    }),
    handleSidebar () {
      return (this.showSidebar = !this.showSidebar)
    },
    confirmLogout () {
      this.loading = !this.loading
      this.logout()
        .finally(() => {
          this.$facebook.logout()
            .finally(() => {
              document.body.style.overflow = 'auto'
              this.$router.push({ name: 'Login' })
              this.loading = !this.loading
            })
            .catch(() => { this.$router.push({ name: 'Login' }) })
        })
    }
  },
  created () {
    if (this.user.isFoodie) {
      this.$repository.users
        .show(this.user.id, {}, { cancelable: false })
        .then((response) => {
          this.profile = response.data
          if (!this.profile.facebook_connected) {
            if (this.$route.path !== '/connection') {
              this.$router.push({ path: '/connection' })
            }
          }
        })
    }
  }
}
</script>
