<template>
  <component :is="`logo-${type}`" />
</template>

<script>
import Logo2 from './logo-2'
import Logo1 from './logo-1'
export default {
  components: {
    Logo2,
    Logo1
  },
  props: {
    type: { required: false, type: [String, Number], default: 1 }
  }
}
</script>
