<template>
  <transition name="fade">
    <button v-if="windowTop >= 250" @click="scrollTo" class="bottom-14 lg:bottom-2 right-0 rounded-full w-12 h-12 fixed bg-primary text-white mr-2 mb-2" :class="color">
      <a class="pi pi-arrow-up" />
    </button>
  </transition>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue'
export default {
  props: {
    color: { required: false, default: '' },
    top: { required: false, default: 0 }
  },
  setup (props) {
    const windowTop = ref(0)
    const scrollTo = () => {
      window.scrollTo({ top: props.top, behavior: 'smooth' })
    }

    const onScroll = (e) => {
      windowTop.value = window.top.scrollY
    }

    onMounted(() => {
      window.addEventListener('scroll', onScroll)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', onScroll)
    })

    return {
      windowTop,
      scrollTo
    }
  }
}
</script>

<style scoped>

</style>
