<template>
  <div :class="[`a-skeleton`, { 'is-circle': circle }]" :style="classBased ? {} : { width, height }" />
</template>

<script>
export default {
  props: {
    width: { required: false, type: String, default: '100%' },
    height: { required: false, type: String, default: '30px' },
    circle: { required: false, type: Boolean, default: false },
    classBased: { required: false, type: Boolean, default: false }
  }
}
</script>

<style scoped>

@keyframes pulse-skeleton-bg-dark {
  0% { background-color: #2e3243; }
  50% { background-color: #292D3E; }
  100% { background-color:#2e3243; }
}

@keyframes pulse-skeleton-bg-light {
  0% { background-color: #ddd; }
  50% { background-color: #d0d0d0; }
  100% { background-color: #ddd; }
}

.a-skeleton {
  display: inline-flex;
  border-radius: 0.4rem;
}

.a-skeleton.is-circle {
  border-radius: 10rem;
}

.a-skeleton {
  animation: pulse-skeleton-bg-light 1.3s infinite;
}

.dark .a-skeleton {
  animation: pulse-skeleton-bg-dark 1.3s infinite;
}
</style>
