<template>
  <img v-bind="{ ...$attrs, src: $attrs.src ?? require('@/assets/of_square_logo.svg') }" />
</template>

<script>
export default {
  inheritAttrs: false,
  methods: {
    error (e) {
      console.log(e)
    }
  }
}
</script>
