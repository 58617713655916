<template>
  <div>
    <slot v-if="$slots.header" name="header" v-bind="{ goNext, goPrev, goStep, index}" />
    <keep-alive>
      <slot v-if="slots === 0" />
      <template v-else>
        <template v-for="slot in slots" :key="slot">
          <slot :name="`step-${slot}`" v-bind="{ goNext, goPrev, goStep, index }" v-if="slot === index" />
        </template>
      </template>
    </keep-alive>
    <slot v-if="$slots.footer" name="footer" v-bind="{ goNext, goPrev, goStep, index }" />
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    slots: { required: false, type: Number, default: 0 }
  },
  setup () {
    const index = ref(1)
    const goNext = () => {
      index.value++
    }
    const goPrev = () => {
      index.value--
    }
    const goStep = step => {
      index.value = step
    }
    return {
      index,
      goNext,
      goPrev,
      goStep
    }
  }
}
</script>
