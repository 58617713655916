/**
 * this file handles the automatic language importation.
 * automatically searching throught the folders in this
 * directory and taking the folder name as language code
 */

const languages = {}
const files = require.context('./', true, /\.json$/i)

files.keys().map(path => {
  const elements = path.split('/').map(e => e.split('.')[0]).filter(e => e)
  const lang = elements[0]

  if (languages.hasOwnProperty.call(lang)) {
    try {
      languages[lang] = {
        ...languages[lang],
        [elements[1]]: files(path)
      }
    } catch (error) {
      console.error(`%c (Language resources) failed to append "${elements[1]}" module at path: ${path}`, 'color: red; font-weight: bold;')
    }
  } else {
    try {
      languages[lang] = { [elements[1]]: files(path) }
    } catch (error) {
      console.error(`%c (Language resources) failed to append "${elements[1]}" module at path: ${path}`, 'color: red; font-weight: bold;')
    }
  }
})

export default languages
