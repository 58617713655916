import { Authenticated, AllowedForTypes } from '@/router/middlewares'
import { UserTypes } from '@/constants'

// import Show from '../../views/authenticated/foodies/restaurant/index.vue'

export default [
  {
    path: '/restaurants/:id',
    name: 'restaurants.show',
    component: () => import('../../views/authenticated/foodies/restaurant/index.vue'),
    meta: {
      middleware: [Authenticated, AllowedForTypes([UserTypes.foodie])]
    }
  }
]
