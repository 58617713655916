export default ({ axios, mapQueryString }) => ({
  plans: () => ({
    default: () => axios.get('/plans/default'),
    actives: () => axios.get('/plans/actives'),
    show: (id) => axios.get(`/plans/${id}`)
  }),
  prepare: () => axios.post('/subscription/prepare'),
  perform: (body = {}) => axios.post('/subscription/perform', body),
  state: () => axios.get('/subscription/state'),
  cancel: () => axios.get('/subscription/cancel'),
  stripeBillingPortal: (query = {}, config = {}) => axios.get(`/subscription/stripe-billing-portal${mapQueryString(query)}`, config),
  swapPlan: (body = {}) => axios.post('/subscription/swap-plan', body)
  // subscriptions: () => ({
  //   prepare: () => axios.post('/subscription/prepare'),
  // })
})
