import { createStore } from 'vuex'
import modules from './modules'
import languages from '@/assets/languages'
import { UserTypes } from '@/constants'

const store = createStore({
  modules,
  state: {
    lang: localStorage.getItem(process.env.VUE_APP_USER_PREFERRED_LANGUAGE) || 'es',
    errors: {},
    cancelTokens: []
  },
  mutations: {
    TRANSLATE (state, lang) {
      state.lang = lang
      return state
    },
    CLEAR_ERRORS (state) {
      state.errors = {}
    },
    REMOVE_ERRORS (state, error) {
      delete state.errors[error]
    },
    SET_ERRORS (state, errors) {
      state.errors = errors
      return state.errors
    },
    ADD_CANCEL_TOKEN (state, token) {
      return state.cancelTokens.push(token)
    },
    CLEAR_CANCEL_TOKENS (state) {
      state.cancelTokens = []
      return state.cancelTokens
    }
  },
  actions: {
    translate ({ commit }, lang = 'es') {
      if (lang) {
        if (lang.length) {
          localStorage.setItem(process.env.VUE_APP_USER_PREFERRED_LANGUAGE, lang)
          commit('TRANSLATE', lang)
        }
      }
    },
    setErrors ({ commit }, errors) {
      commit('SET_ERRORS', errors)
    },
    clear ({ commit, dispatch }, error) {
      commit('REMOVE_ERRORS', error)
    },
    vanishErrors ({ commit }) {
      commit('SET_ERRORS', {})
    },
    cancelPendingRequest ({
      state,
      commit
    }) {
      // Cancel all request where a token exists
      state.cancelTokens.forEach(request => {
        if (request.cancel) {
          request.cancel()
        }
      })

      // Reset the cancelTokens store
      commit('CLEAR_CANCEL_TOKENS')
    }
  },
  getters: {
    lang: ({ lang }) => languages[lang],
    has: ({ errors }) => name => name in errors,
    get: ({ errors }) => name => errors[name][0],
    error: (_, {
      has,
      get
    }) => name => has(name) ? get(name) : undefined,
    routes: ({ session }, { lang }, _, rootGetters) => {
      const type = session.user?.user?.type ?? session.user?.type
      const routes = [
        {
          to: { name: 'dashboard' },
          label: lang.app.navigation.dashboard.index,
          icon: 'home',
          visible: true
        }
      ]

      const adminRoutes = [
        {
          to: { name: 'customers.index' },
          label: lang.app.navigation.customers.index,
          icon: 'users',
          visible: true
        }
      ]

      return (() => {
        switch (type) {
          case UserTypes.admin:
            return adminRoutes
          default:
            return routes
        }
      })()
      // .sort((a, b) => a.label > b.label ? 1 : -1) // to sort routes alphabetically
    }
  }
})

export default store
