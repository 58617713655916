export const URL = process.env.VUE_APP_NODE_ENV === 'dev'
  ? process.env.VUE_APP_DEVELOPMENT_API_URL
  : process.env.VUE_APP_PRODUCTION_API_URL

export const UserTypes = {
  foodie: 1,
  restaurant: 2,
  admin: 3
}

export const Errors = {
  route: {
    userIsUnauthenticated: 1,
    userIsUnauthorized: 2,
    userDoesntHasPermission: 3
  },
  api: {
    authentication: {
      invalidCredentials: 'EA001',
      notConfirmed: 'EA002',
      alreadyConfirmed: 'EA003',
      resetTokenExpired: 'EA005',
      emailEqualsCurrent: 'EA006',
      emailAlreadyTaken: 'EA007',
      invalidPassword: 'EA008',
      userDisabled: 'EA009'
    },
    box: {
      productNotAvailable: 'EB001',
      productInvalidQuantity: 'EB002'
    },
    generics: {
      deleting: 'EG001',
      deletingLastAdmin: 'EG002',
      selfDeleting: 'EG003',
      deletingReference: 'EG004'
    }
  }
}
