import { createApp } from 'vue'
import axios from 'axios'
import store from './store'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import configuration from './configuration'
import initialiceFacebookSDK from './sdk/facebook'
import Debounce from './directives/debounce'
import { moment, dateFormatter, currencyFormatter } from './filters'
import { Repository } from './api'
import './assets/solofoodies_font.css'
import './assets/tailwind.css'
import './assets/custom.css'
// import './assets/range.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import './assets/icommon.css'
import './assets/preloader.css'
import Alert from './components/ui/Alert.vue'
import Loader from './components/ui/Loader.vue'
import List from './components/ui/List.vue'
import Icon from './components/icon/Icon.vue'
import Dropdown from './components/dropdown/Dropdown.vue'
import DropdownItem from './components/dropdown/DropdownItem.vue'
import Stepper from './components/ui/Stepper.vue'
import DimnsSearchBar from './components/ui/DimsSearchBar.vue'
import Picture from './components/ui/Picture.vue'
import LogoSwitch from './components/logos/LogoSwitch'
import Initials from './components/logos/initials'
import Skeleton from './components/ui/Skeleton'
import SegmentedControl from './components/ui/SegmentedControl'
import ScrollTo from './components/ui/ScrollTo'
import PrimeVue from 'primevue/config'

router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0)
  next()
})

const components = {
  install: (Vue) => {
    Vue.component('a-alert', Alert)
    Vue.component('a-loader', Loader)
    Vue.component('a-list', List)
    Vue.component('a-icon', Icon)
    Vue.component('a-dropdown', Dropdown)
    Vue.component('a-dropdown-item', DropdownItem)
    Vue.component('a-stepper', Stepper)
    Vue.component('a-dims-search-bar', DimnsSearchBar)
    Vue.component('a-picture', Picture)
    Vue.component('a-logo-switch', LogoSwitch)
    Vue.component('a-initials', Initials)
    Vue.component('a-skeleton', Skeleton)
    Vue.component('a-segmented-control', SegmentedControl)
    Vue.component('a-scroll', ScrollTo)
  }
}

const initialiceApp = (response) => {
  const application = createApp(App)

  if (process.env.VUE_APP_NODE_ENV === 'production') {
    Sentry.init({
      App,
      dsn: process.env.VUE_APP_SENTRY_KEY,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: ['solofoodies.com', /^\//]
        })
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0
    })
  }

  application
    .use(router)
    .use(store)

  configuration({
    axios,
    router,
    store,
    vue: application,
    // notification: Vue.prototype.$message,
    lang: store.getters.lang
  })

  application
    .use(Repository, { store })
    .use(Debounce)
    .use(PrimeVue, {
      locale: {
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
        dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
        monthNames: ['Enero', 'Febrero', 'Marzo', 'April', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        firstDayOfWeek: 1
      }
    })
    .use(components)
    .mount('#app')

  application.config.globalProperties.$console = console

  application.config.globalProperties.$filters = {
    moment,
    dateFormatter,
    currencyFormatter,
    upperCaseFirst: value => value.charAt(0).toUpperCase() + value.slice(1),
    /**
     *
     * @param date {Date | number}
     * @param options {Intl.DateTimeFormatOptions}
     * @param localization {string}
     * @returns {string}
     */
    intl: (date, options = {}, localization = 'es-ES') => new Intl.DateTimeFormat(localization, options)
      .format(date),
    collaboration: {
      discount: (type, value) => {
        switch (type) {
          case 'prc': return `${value}%`
          case 'fixed_e2': return `${value / 100}€`
          default: return 'invalid_operation'
        }
      }
    }
  }

  application.provide('$filters', application.config.globalProperties.$filters)

  const responseHandler = (resolve, reject) => (response) => {
    // console.log('FACEBOOK RESPONSE: ', response)
    if (response.error) {
      return reject(response)
    }

    return resolve(response)
  }

  const checkFacebookStatus = () => {
    return new Promise((resolve, reject) => {
      window.FB.getLoginStatus(response => {
        if (response.status === 'connected') {
          resolve(response)
        } else {
          reject(Error('not connected to facebook'))
        }
      })
    })
  }

  application.config.globalProperties.$facebook = {
    login: window.FB.login,
    logout: () => new Promise((resolve, reject) => {
      window.FB.getLoginStatus(response => {
        if (response.status === 'connected') {
          window.FB.logout(() => {
            resolve()
          })
        } else {
          reject(Error('not connected to facebook'))
        }
      })
    }),
    get: (path, body = null) => new Promise((resolve, reject) => {
      checkFacebookStatus()
        .then(({ authResponse }) => {
          window.FB.api(path(authResponse), 'get', body, responseHandler(resolve, reject))
        })
    }),
    api: {
      get: (path, body = null) => new Promise((resolve, reject) => {
        checkFacebookStatus()
          .then(({ authResponse }) => {
            window.FB.api(path(authResponse), 'get', body, responseHandler(resolve, reject))
          })
      }),
      delete: (path, body) => new Promise((resolve, reject) => {
        checkFacebookStatus()
          .then(({ authResponse }) => {
            window.FB.api(path(authResponse), 'delete', body, responseHandler(resolve, reject))
          })
      })
    }
  }

  if (response) {
    if (store.getters['session/isAuthenticated']) {
      return router.push({ name: 'Explore' })
    }

    store.commit('session/SET_TEMPORAL_FB_LOGIN', response)
  }
}

initialiceFacebookSDK()
  .then(response => {
    initialiceApp(response)
  })
  .catch(err => {
    console.log('error', err)
  })

// initialiceApp(null)
