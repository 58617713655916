export default ({
  next,
  store: { getters }
}) => {
  const premium = getters['foodies/isPremium']

  if (!premium) {
    return next({ name: 'Explore' })
  }

  return next()
}
