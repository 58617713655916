/**
 * This is the main axios handler, where is configured to handle
 * all the incoming and upconfimg requests
 */
import { URL } from '@/constants'

const untokenedURLs = ['/api/auth/login']

// eslint-disable-next-line no-unused-vars
const buildNotification = ({
  notification,
  title = 'Error',
  message = 'Ha sucedido un error inesperado',
  type = 'info'
}) => {
  notification({
    title,
    text: message,
    type
  })
}

/**
 * implemented at ~/main.js
 */
export default ({
  axios,
  store,
  router
}) => {
  axios.defaults.baseURL = URL

  /**
   * the config must always be returned
   * @description this function is called after the request is sent
   * @return {JSON} config
   * @throws {Response} error
   */
  axios.interceptors.response.use(response => {
    if (response.headers.authorization) {
      store.dispatch('session/setNewToken', response.headers.authorization.split(' ')[1])
    }
    return response
  }, error => {
    /**
     * to start the axios request validation we must start validating
     * that the response is readable, if its readable we can proceed
     * to read the status codes.
     */
    // eslint-disable-next-line no-prototype-builtins
    if (error.hasOwnProperty('response')) {
      if (error.response.data.message === 'The token has been blacklisted') {
        // const {} = store
        store.dispatch('session/vanish')
        router.push({ name: 'Login' })
        // if (!session.showLoginModal) {
        //   commit('session/TOGGLE_LOGIN_MODAL')
        // }
      }

      if (error.response?.data?.message === 'Token has expired and can no longer be refreshed') {
        store.dispatch('session/vanish')
        router.push({ name: 'Login' })
      }

      if (error.response?.data?.message === 'Token not provided') {
        store.dispatch('session/vanish')
        router.push({ name: 'Login' })
      }

      switch (error?.response?.status) {
        case 400:
        case 403:
          console.log(error?.response?.status)
          break
        case 402:/*
          const { error_code } = error.response?.data

          console.log(lang)

          if (error_code) {
            buildNotification({
              notification,
              title: lang.notificationMessages.api_errors[error_code]?.title,
              message: lang.notificationMessages.api_errors[error_code]?.message,
              type: 'error'
            })
          }**/
          break
        case 401:
          console.log(error?.response?.status)
          break
        case 422:
          store.dispatch('setErrors', error?.response?.data?.errors)
          break
        default:
          break
      }
    } else {
      if (!axios.isCancel(error)) {

      }
    }
    return Promise.reject(error)
  })

  /**
   * the config must always be returned
   * @description this function is called before send the request to the server
   * @return {JSON} config
   * @throws {Response} error
   */
  axios.interceptors.request.use(config => {
    const user = JSON.parse(localStorage.getItem(process.env.VUE_APP_USER_STORE_KEY))
    const token = localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_STORE_KEY)

    if (user && !untokenedURLs.includes(config.url) && token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    if (config.customHeaders) {
      config.headers = {
        ...config.headers,
        ...config.customHeaders
      }
    }

    if (config.cancelable === undefined || config.cancelable === true) {
      const source = axios.CancelToken.source()

      // Set cancel token on this request
      config.cancelToken = source.token

      // Add to vuex to make cancellation available from anywhere
      store.commit('ADD_CANCEL_TOKEN', source)
    }
    // const source = axios.CancelToken.source()
    //
    // // Set cancel token on this request
    // config.cancelToken = source.token
    //
    // // Add to vuex to make cancellation available from anywhere
    // store.commit('ADD_CANCEL_TOKEN', source)

    config.url = encodeURI(config.url)

    return config
  }, error => (Promise.reject(error)))
}
