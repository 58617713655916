<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="843.412" height="50" viewBox="0 0 843.412 218.535">
    <g id="Grupo_8" data-name="Grupo 8" transform="translate(-538.294 -1602.466)">
      <g id="Grupo_7" data-name="Grupo 7">
        <g id="Grupo_5" data-name="Grupo 5">
          <path id="Trazado_15" data-name="Trazado 15" d="M1372.933,1677.076a8.831,8.831,0,1,0,8.773,8.819A8.772,8.772,0,0,0,1372.933,1677.076Zm6.392,8.819a6.4,6.4,0,1,1-6.392-6.624A6.454,6.454,0,0,1,1379.325,1685.9Z" fill="#1d252c"/>
          <path id="Trazado_16" data-name="Trazado 16" d="M1376.423,1684.182a2.944,2.944,0,0,0-3.115-2.894h-3.179v9.084h1.92v-3.3h.461l1.846,3.3h2.2l-2.011-3.507A2.845,2.845,0,0,0,1376.423,1684.182Zm-4.374-1.194h1.018c.911,0,1.413.432,1.413,1.216,0,.756-.5,1.172-1.413,1.172h-1.018Z" fill="#1d252c"/>
        </g>
        <g id="Grupo_6" data-name="Grupo 6">
          <path id="Trazado_17" data-name="Trazado 17" d="M792.629,1672.051a47.954,47.954,0,0,1,23.075,5.989v14.092q-12.741-7.69-22.6-7.692-5.989,0-9.365,2.319a7.17,7.17,0,0,0-3.376,6.195q0,4.64,6.282,8.572,1.761,1.174,13.564,6.576,10.1,4.756,14.561,10.569a22.278,22.278,0,0,1,4.462,13.974q0,10.569-7.6,17.056t-19.7,6.489a46.59,46.59,0,0,1-13.533-1.733,58.564,58.564,0,0,1-12.712-5.959V1732.88q13.152,10.922,24.778,10.921a15.97,15.97,0,0,0,9.394-2.524,7.818,7.818,0,0,0,3.582-6.694q0-5.694-6.224-9.278-5.168-2.817-13.328-6.752-9.688-4.4-14.474-10.274a20.93,20.93,0,0,1-4.784-13.622q0-10.334,7.691-16.47T792.629,1672.051Z" fill="#1d252c"/>
          <path id="Trazado_18" data-name="Trazado 18" d="M857.246,1699.237a33.612,33.612,0,0,1,15.559,3.581,26.037,26.037,0,0,1,10.8,10.04,28.2,28.2,0,0,1,3.816,14.62,29.468,29.468,0,0,1-3.669,14.914,25.737,25.737,0,0,1-10.8,10.1,33.608,33.608,0,0,1-15.707,3.7,33.207,33.207,0,0,1-15.677-3.7,25.835,25.835,0,0,1-10.715-10.1,29.448,29.448,0,0,1-3.67-14.914,27.935,27.935,0,0,1,3.875-14.708,26.374,26.374,0,0,1,10.8-9.982A33.231,33.231,0,0,1,857.246,1699.237Zm-14.913,28.241q0,8.221,4.021,13.123a13.223,13.223,0,0,0,10.775,4.9q7.045,0,11.1-4.7t4.051-13.447q0-8.336-4.08-12.887t-11.068-4.551a13.387,13.387,0,0,0-10.686,4.815Q842.332,1719.552,842.333,1727.478Z" fill="#1d252c"/>
          <path id="Trazado_19" data-name="Trazado 19" d="M912.7,1673.108v82.025H898.259v-82.025Z" fill="#1d252c"/>
          <path id="Trazado_20" data-name="Trazado 20" d="M953.6,1699.237a33.612,33.612,0,0,1,15.559,3.581,26.037,26.037,0,0,1,10.8,10.04,28.2,28.2,0,0,1,3.817,14.62,29.458,29.458,0,0,1-3.67,14.914,25.737,25.737,0,0,1-10.8,10.1,33.608,33.608,0,0,1-15.707,3.7,33.2,33.2,0,0,1-15.676-3.7,25.844,25.844,0,0,1-10.716-10.1,29.448,29.448,0,0,1-3.67-14.914,27.926,27.926,0,0,1,3.876-14.708,26.371,26.371,0,0,1,10.8-9.982A33.231,33.231,0,0,1,953.6,1699.237Zm-14.913,28.241q0,8.221,4.021,13.123a13.223,13.223,0,0,0,10.775,4.9q7.046,0,11.1-4.7t4.051-13.447q0-8.336-4.08-12.887t-11.068-4.551a13.388,13.388,0,0,0-10.686,4.815Q938.686,1719.552,938.686,1727.478Z" fill="#1d252c"/>
          <path id="Trazado_21" data-name="Trazado 21" d="M1017.218,1672.051a41,41,0,0,1,9.512,1.443v12.019c-3.447-.946-11.635-1.839-14.664,2.23-1.48,1.989-2.187,4.428-2.187,8.617v3.933h15.584v10.921h-15.584v43.919H995.435v-43.919h-7.808v-10.921h7.808v-4.11q0-11.1,5.96-17.615T1017.218,1672.051Z" fill="#1d252c"/>
          <path id="Trazado_22" data-name="Trazado 22" d="M1060.684,1699.237a33.613,33.613,0,0,1,15.559,3.581,26.037,26.037,0,0,1,10.8,10.04,28.2,28.2,0,0,1,3.816,14.62,29.467,29.467,0,0,1-3.669,14.914,25.734,25.734,0,0,1-10.8,10.1,33.6,33.6,0,0,1-15.706,3.7,33.2,33.2,0,0,1-15.677-3.7,25.836,25.836,0,0,1-10.715-10.1,29.448,29.448,0,0,1-3.67-14.914,27.935,27.935,0,0,1,3.875-14.708,26.375,26.375,0,0,1,10.8-9.982A33.23,33.23,0,0,1,1060.684,1699.237Zm-14.914,28.241q0,8.221,4.022,13.123a13.223,13.223,0,0,0,10.775,4.9q7.046,0,11.1-4.7t4.051-13.447q0-8.336-4.081-12.887t-11.067-4.551a13.387,13.387,0,0,0-10.686,4.815Q1045.77,1719.552,1045.77,1727.478Z" fill="#1d252c"/>
          <path id="Trazado_23" data-name="Trazado 23" d="M1128.412,1699.237a33.62,33.62,0,0,1,15.56,3.581,26.043,26.043,0,0,1,10.8,10.04,28.2,28.2,0,0,1,3.816,14.62,29.458,29.458,0,0,1-3.67,14.914,25.737,25.737,0,0,1-10.8,10.1,33.608,33.608,0,0,1-15.707,3.7,33.2,33.2,0,0,1-15.676-3.7,25.843,25.843,0,0,1-10.716-10.1,29.447,29.447,0,0,1-3.669-14.914,27.935,27.935,0,0,1,3.875-14.708,26.378,26.378,0,0,1,10.8-9.982A33.233,33.233,0,0,1,1128.412,1699.237Zm-14.913,28.241q0,8.221,4.022,13.123a13.22,13.22,0,0,0,10.774,4.9q7.045,0,11.1-4.7t4.052-13.447q0-8.336-4.081-12.887t-11.068-4.551a13.389,13.389,0,0,0-10.686,4.815Q1113.5,1719.552,1113.5,1727.478Z" fill="#1d252c"/>
          <path id="Trazado_24" data-name="Trazado 24" d="M1206.005,1701.526v-28.418h14.385v82.025h-25.364q-13.154,0-21.05-7.574t-7.9-20.668a27.62,27.62,0,0,1,3.67-14.092,26.372,26.372,0,0,1,10.1-9.952,27.891,27.891,0,0,1,13.886-3.61A37.1,37.1,0,0,1,1206.005,1701.526Zm-7.574,42.569h7.574V1712.33a20.068,20.068,0,0,0-9.277-2.29q-7.221,0-11.537,4.521t-4.316,11.86Q1180.875,1744.1,1198.431,1744.095Z" fill="#1d252c"/>
          <path id="Trazado_25" data-name="Trazado 25" d="M1241.324,1674.928a8.219,8.219,0,0,1,8.161,8.162,8.06,8.06,0,0,1-2.378,5.724,7.88,7.88,0,0,1-11.508,0,7.964,7.964,0,0,1-2.437-5.724,7.732,7.732,0,0,1,2.467-5.783A7.958,7.958,0,0,1,1241.324,1674.928Zm7.222,25.365v54.84H1234.1v-54.84Z" fill="#1d252c"/>
          <path id="Trazado_26" data-name="Trazado 26" d="M1286.8,1699.237q12.095,0,19.141,7.75t7.046,21.43v2.173h-38.81q.88,7.341,5.489,11.3t12.653,3.963a28.153,28.153,0,0,0,9.953-1.791,43.518,43.518,0,0,0,9.892-5.431v10.745q-9.628,6.812-23.368,6.811-12.977,0-21.2-7.809t-8.22-20.786q0-12.505,7.6-20.432T1286.8,1699.237Zm12.624,22.6q-.588-5.988-3.641-9.364a10.747,10.747,0,0,0-8.4-3.377,12.047,12.047,0,0,0-8.778,3.23q-3.318,3.23-4.2,9.511Z" fill="#1d252c"/>
          <path id="Trazado_27" data-name="Trazado 27" d="M1340.906,1699.237a34.087,34.087,0,0,1,8.513,1.027,43.005,43.005,0,0,1,8.455,3.259v11.566a40.7,40.7,0,0,0-8.807-4.256,26.2,26.2,0,0,0-8.4-1.615,8.708,8.708,0,0,0-4.873,1.174,3.789,3.789,0,0,0-1.761,3.347,3.372,3.372,0,0,0,1.467,2.466,54.671,54.671,0,0,0,8.044,4.521q9.395,4.755,13.182,8.778a13.59,13.59,0,0,1,3.787,9.659q0,7.926-5.52,12.476t-14.913,4.551a44.381,44.381,0,0,1-10.951-1.292,36.675,36.675,0,0,1-8.424-3.112V1739.75q10.568,5.871,17.79,5.871a11.909,11.909,0,0,0,5.989-1.321,3.947,3.947,0,0,0,2.289-3.493,3.735,3.735,0,0,0-.822-2.349,10.794,10.794,0,0,0-2.466-2.173q-1.644-1.115-10.274-5.343-6.637-3.171-9.806-6.9a13.207,13.207,0,0,1-3.17-8.837,13.88,13.88,0,0,1,5.695-11.626Q1331.628,1699.237,1340.906,1699.237Z" fill="#1d252c"/>
        </g>
      </g>
      <path id="Trazado_28" data-name="Trazado 28" d="M692.7,1641.358h0l12.705-15.142h0A101.716,101.716,0,0,0,538.69,1713q.285,3.259.771,6.469a100.956,100.956,0,0,0,35.177,62.584l-12.706,15.142a100.963,100.963,0,0,0,67.742,23.775q3.243-.079,6.505-.365A101.716,101.716,0,0,0,692.7,1641.358Zm-17.742,117.893a62.192,62.192,0,0,1-87.615,7.665l12.706-15.141a62.189,62.189,0,1,1,79.948-95.28h0l-12.706,15.141h0A62.26,62.26,0,0,1,674.954,1759.251Z" fill="#ff4438"/>
    </g>
  </svg>
</template>
