import { useStore } from 'vuex'

export const useSession = () => {
  const { state: { session: { user } }, getters } = useStore()

  const belongsToFoodie = (id) => user?.foodie?.id === id
  const belongsToRestaurant = (id) => user?.restaurant?.id === id

  return {
    user,
    isAuthenticated: getters['session/isAuthenticated'],
    belongsToFoodie,
    belongsToRestaurant
  }
}
