<template>
  <component
    :is="to ? 'router-link' : href ? 'a' : 'button'"
    :to="to"
    :href="href"
    type="button"
    :disabled="disabled"
    :class="[$style.dropdownItem, {[$style.dropdownItemDisabled]: disabled}]">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    to: { required: false, type: [String, Object], default: undefined },
    href: { required: false, type: String, default: undefined },
    disabled: { required: false, type: Boolean, default: false }
  }
}
</script>

<style module>
.dropdownItem {
    @apply flex appearance-none w-full justify-between items-center rounded focus:outline-none focus:ring-2 focus:ring-primary px-2 py-1 my-1 cursor-pointer;
    @apply hover:bg-gray-200 text-gray-700 font-medium text-gray-700 font-medium;
}

.dropdownItemDisabled {
    @apply text-gray-400 cursor-not-allowed;
}
</style>
