/**
 * \
 * @param axios
 * @param mapQueryString {function(object): string}
 * @param withoutInvalidValues {function(object): object}
 * @param toFormData {function(object): FormData}
 * @returns {{cancel: (function(*): *|Promise<AxiosResponse<any>>), foodie: (function(*): {cancel: function(*): *, setIgnored: function(*): *, postShared: function(*): *, setAttended: function(*): *, show: function(*, *=): *, ignore: function(*): *, accept: function(*): *}), collaboration: (function(*): {rateRestaurant: function(*, *=): *, handShake: function(*, *=): *, markAssisted: function(*): *, rateFoodie: function(*, *=): *}), show: (function(*, *=): *), available: (function(*=, *=): *), index: (function(*=, *=): *), update: (function(*, *=): *|Promise<AxiosResponse<any>>), store: (function(*=): *), delete: (function(*): *)}}
 */
export default ({ axios, mapQueryString, withoutInvalidValues, toFormData }) => ({
  index: (query = {}, config = {}) => axios.get(`/collaborations${mapQueryString(query)}`, config),
  show: (id, query = {}) => axios.get(`/collaborations/${id}${mapQueryString(query)}`),
  store: (body = {}) => axios.post('/collaborations', body),
  update: (id, body = {}) => axios.patch(`/collaborations/${id}`, body),
  delete: (id) => axios.delete(`/collaborations/${id}`),
  cancel: (id) => axios.patch(`/collaborations/${id}/set-canceled`),
  // just for restaurants
  collaboration: (collaboration) => ({
    markAssisted: (foodie) => axios.patch(`/collaborations/${collaboration}/foodies/${foodie}/foodie-attended`),
    rateFoodie: (foodie, body = {}) => axios.patch(`/collaborations/${collaboration}/foodies/${foodie}/foodie-rating`, body),
    rateRestaurant: (foodie, body = {}) => axios.patch(`/collaborations/${collaboration}/foodies/${foodie}/restaurant-rating`, body),
    handShake: (foodie, body = {}) => axios.patch(`/collaborations/${collaboration}/foodies/${foodie}/confirm-foodie`, body)
  }),
  // just for foodie usage
  available: (query = {}, configuration = {}) => axios.get(`/collaborations/available${mapQueryString(query)}`, configuration),

  withFoodies: (collaboration, query = {}) => axios.get(`/collaborations/${collaboration}/foodies${mapQueryString(query)}`),
  addFoodies: (collaboration, body = {}) => axios.post(`/collaborations/${collaboration}/foodies`, body),

  // just for foodie user
  foodie: (foodie) => ({
    show: (collaboration, query = {}) => axios.get(`/collaborations/${collaboration}/foodies/${foodie}${mapQueryString(query)}`),
    accept: (collaboration) => axios.patch(`/collaborations/${collaboration}/foodies/${foodie}/set-accepted`),
    ignore: (collaboration) => axios.patch(`/collaborations/${collaboration}/foodies/${foodie}/set-ignored`),
    cancel: (collaboration) => axios.patch(`/collaborations/${collaboration}/foodies/${foodie}/set-canceled`),
    postShared: (collaboration, body = {}) => axios.patch(`/collaborations/${collaboration}/foodies/${foodie}/post-shared`, body),
    setAttended: (collaboration) => axios.patch(`/collaborations/${collaboration}/foodies/${foodie}/set-attended`),
    setIgnored: (collaboration) => axios.patch(`/collaborations/${collaboration}/foodies/${foodie}/set-ignored`),
    generateSololink: (collaboration) => axios.post(`collaborations/${collaboration}/foodies/${foodie}/solo-links`)
  }),
  requirements: () => axios.get('/collaborations/default-requirements'),
  defaultDays: () => axios.get('/collaborations/default-allowed-week-days')
})
