<template>
  <div />
</template>

<script>
export default {
  name: 'stripejs',
  setup () {
    (function (doc, script, id) {
      const stripeJs = doc.getElementsByTagName(script)[0]

      if (doc.getElementById(id)) {
        return
      }

      const js = doc.createElement(script)
      js.id = id
      js.src = 'https://js.stripe.com/v3/'
      stripeJs.parentNode.insertBefore(js, stripeJs)
    }(document, 'script', 'stripe-js'))
  }
}
</script>

<style scoped>

</style>
