import { onMounted, onUnmounted, ref, computed } from 'vue'

const sizes = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536
}

const useWindowSize = () => {
  const width = ref(window.innerWidth)
  const isSm = computed(() => width.value < sizes.md)
  const isMd = computed(() => width.value >= sizes.md && width.value <= sizes.lg)
  const isLg = computed(() => width.value >= sizes.lg && width.value <= sizes.xl)
  const isXl = computed(() => width.value >= sizes.xl && width.value <= sizes['2xl'])
  const is2xl = computed(() => width.value >= sizes['2xl'])
  const isDesktop = computed(() => width.value >= sizes.lg)

  const handleResize = (event) => {
    width.value = event.target.innerWidth
  }

  onMounted(() => {
    window.addEventListener('resize', handleResize)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', handleResize)
  })

  return {
    width,
    isSm,
    isMd,
    isLg,
    isXl,
    is2xl,
    isDesktop
  }
}

export default useWindowSize
