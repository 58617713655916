<template>
  <stepper :slots="2" class="h-screen flex flex-col stepper-padding bg-white">

    <template #step-1>
      <step-plans
        v-model:plan="selectedPlan"
        :profile="profile"
        :planValues="planValues"
      />
    </template>

    <template #step-2>
      <step-checkout
        :plan="selectedPlan"
      />
    </template>

<!--    <template #footer="{ index }">-->
<!--      <div>-->
<!--        -->
<!--      </div>-->
<!--    </template>-->

    <template #footer="{ index, goPrev, goNext }">
      <div class="fixed inset-x-0 container mx-auto bottom-0 max-w-5xl">
        <div class="flex justify-between py-4 px-8 mb-14">
          <button
            class="bg-onboarding_gray focus:outline-none flex justify-center p-2 rounded-full uppercase disabled:bg-onboarding_light_gray transition-all ease-in-out duration-300"
            v-if="index === 2"
            @click="goPrev"
          >
            <button class="rounded-full h-10 w-10 text-white">
              <i class="icon-arrowLeft"></i>
            </button>
          </button>
          <div v-else/>

          <button
            class="bg-onboarding_gray focus:outline-none flex justify-center p-2 rounded-full uppercase disabled:bg-onboarding_light_gray transition-all ease-in-out duration-300"
            v-if="index === 1"
            @click="goNext"
          >
            <a-loader v-if="loading" color="text-black"
                      class="h-10 w-10"/>
            <button class="rounded-full h-10 w-10 text-white">
              <i class="icon-arrowRight"></i>
            </button>
          </button>
          <div v-else/>
        </div>
      </div>
    </template>
  </stepper>
</template>

<script>
import { ref } from 'vue'
import Stepper from '../../../components/ui/Stepper'
import StepPlans from './step-plans'
import StepCheckout from './step-checkout'
// import { useRepository } from '../../../hooks'

export default {
  name: 'stripe',
  props: {
    profile: { required: false, type: Object },
    planValues: { required: false, type: Object }
  },
  components: {
    Stepper,
    StepPlans,
    StepCheckout
  },
  setup () {
    const loading = ref(false)
    const selectedPlan = ref({})
    // const stripe = useRepository(({ stripe }) => stripe)
    return {
      loading,
      selectedPlan
    }
  }
}
</script>

<style scoped>

</style>
