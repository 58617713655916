export default ({ axios, mapQueryString, withoutInvalidValues }) => ({
  foodie: (foodie) => ({
    index: (query = {}) => axios.get(`/foodies/${foodie}/invitations${mapQueryString(query)}`)
  }),
  restaurant: (restaurant) => ({
    index: (query = {}) => axios.get(`/restaurants/${restaurant}/invitations${mapQueryString(query)}`)
  }),
  send: (invitation) => axios.patch(`/invitations/${invitation}/send`),
  decode: (code) => axios.post(`/invitations/decode/${code}`),
  code: (id) => axios.get(`/invitations/${id}/code`),
  generate: (config = {}) => axios.post('/invitations/generate', null, config),
  getInvitations: (id) => axios.get(`/users/${id}/invitation-batches/last`)
})
