<template>
  <aside
    class="bg-black bg-opacity-50 inset-0 z-80"
    v-if="mappedPermissions">

    <transition name="fade">
      <div @click="handleSidebar" class="fixed inset-0 bg-black z-80 bg-opacity-50" v-if="show" />
    </transition>

    <div class="flex flex-col fixed w-80 bg-white right-0 z-80 inset-y-0 transition-transform transform" :class="{
          'translate-x-80': !show,
          'translate-x-0': show
        }">
      <div class="p-normal text-left sidebar-i-container border-b border-gray">
        <div>
          <a-initials :name="profile.name" :src="profile.profile_picture"></a-initials>
          <p class="text-lg font-regular pt-extra">{{ profile.name }}</p>
        </div>
      </div>
      <div class="py-2">
        <div class="flex-grow pt-small">
          <router-link to="/politics" class="w-full block paddines_link cursor-pointer text-left">
            Políticas de privacidad
          </router-link>
          <router-link to="/terms" class="w-full block paddines_link cursor-pointer text-left">
            Condiciones de Uso y Contratación
          </router-link>
          <router-link to="/cookies-politics" class="w-full block paddines_link cursor-pointer text-left">
            Politica de Cookies
          </router-link>
        </div>
      </div>

      <div class="flex-grow pt-small">
      </div>
      <div>
        <a-logo-switch type="1" class="w-40 select-none lowercase inline-block"/>
      </div>
      <div class="w-full bottom-0 border-t border-gray">
        <button v-if="environment" @click="$emit('destroySession')" class="cursor-pointer text-red p-3 w-full text-left">
          <i class="icon-logout text-xxl pr-sm align-middle" />
          DESTRUIR SESIÓN
        </button>
        <button @click="$emit('closeSession');handleSidebar" class="cursor-pointer p-3 w-full text-left">
          <i class="icon-logout text-xxl pr-sm align-middle" />
          Cerrar sesión
        </button>
      </div>
    </div>
  </aside>

  <aside v-else class="bg-black bg-opacity-50 inset-0 z-80">

    <transition name="fade">
      <div @click="handleSidebar" class="fixed inset-0 bg-black z-80 bg-opacity-50" v-if="show" />
    </transition>

    <div class="flex flex-col w-12 fixed w-80 bg-white right-0 z-80 inset-y-0 transition-transform transform" :class="{
          'translate-x-80': !show,
          'translate-x-0': show
        }">
      <div class="p-normal text-left sidebar-i-container">
        <div class="flex items-center justify-center justify-between">
          <div class="flex-grow flex flex-row">
            <div class="">
              <a-initials :name="profile?.name" :src="profile?.profile_picture" :desing-class="'w-14 h-14'"/>
            </div>
            <div class="ml-1 flex flex-col justify-center">
              <p class="text-lg font-regular">{{ profile?.name }}</p>
              <p v-if="profile?.ig_username" class="text-gray font-light">{{ '@' + profile?.ig_username}}</p>
            </div>
          </div>
          <div v-if="userSession.isFoodie" @click="$router.push({ name: 'balance.index' })" class="flex-none text-md cursor-pointer" style="font-weight: 500">
            <p class="border rounded-full px-4 py-1">{{ mappedBalance.replace('.', ',') }}</p>
          </div>
        </div>
      </div>
      <div class="border-b border-t border-gray py-2 mx-2">
        <router-link :to="{ path: `/profile/${getUserId}`}" active-class="text-SFRed" class="p-2 block w-full text-left">
          <i class="icon-profile text-xxl align-middle pr-sm" />
          Mi perfil
        </router-link>
        <router-link v-if="userSession.isFoodie" :to="{ name: 'collaborations.index' }" active-class="text-SFRed" class="p-2 block w-full text-left">
          <i class="icon-colaborate text-xxl align-middle pr-sm" />
          Colaboraciones
        </router-link>
        <router-link v-if="userSession.isRestaurant" :to="{ name: 'collaborations.index' }" active-class="text-SFRed" class="p-2 block w-full text-left">
          <i class="icon-colaborate text-xxl align-middle pr-sm" />
          Colaboraciones
        </router-link>
        <router-link v-if="userSession.isFoodie && profile?.is_premium" :to="{ path: '/rates' }" active-class="text-SFRed" class="p-2 block w-full text-left">
          <i class="icon-tc text-xxl align-middle pr-sm" />
          Mis tarifas
        </router-link>
        <router-link :to="{ path: '/historial' }" active-class="text-SFRed" class="p-2 block w-full text-left">
          <i class="icon-countdown text-xxl align-middle pr-sm" />
          Historial
        </router-link>
<!--        <div v-if="missingValues.userValidated">-->
        <button @click="$emit('sendInvitation')" class="p-2 block w-full text-left">
          <i class="icon-messageOpen text-xxl align-middle pr-sm" />
          Enviar invitación
        </button>
        <button v-if="userSession.isFoodie" class="p-2 block w-full text-left" @click="$emit('goToSololink')">
            <i class="icon-group text-xxl align-middle pr-sm" />
          Ir a sololink
        </button>
        <button v-if="userSession.isRestaurant" class="p-2 block w-full text-left" @click="$emit('handleSubscriptionLink')">
          <a-loader v-if="loading" class="h-6 w-6 inline-block mr-sm" :color="'text-gray-400'"/>
          <i v-else class="icon-bookmark text-xxl align-middle pr-sm" />
          <span class="inline-block">Suscripción</span>
        </button>
        <a v-if="userSession.isFoodie" href="https://www.youtube.com/playlist?list=PLdurWu2a_HN9BCVqnq7-h7HCqDD4zpTJ-" target="_blank" class="block p-2 text-left">
          <i class="icon-userGroup text-xxl align-middle pr-sm inline-block" />
          <span class="inline-block">Tutoriales</span>
        </a>
        <a v-if="userSession.isRestaurant" href="https://www.youtube.com/playlist?list=PLdurWu2a_HN-YaelObU6KgTS5APdRONKl" target="_blank" class="block p-2 text-left">
          <i class="icon-userGroup text-xxl align-middle pr-sm inline-block" />
          <span class="inline-block">Tutoriales</span>
        </a>
        <div v-if="stripeLinkError !== ''">
          <p class="text-sm text-red-400">{{ stripeLinkError }}</p>
        </div>
      </div>

      <div class="flex-grow pt-small">
        <router-link :to="{ name: 'Politics' }" active-class="text-SFRed" class="w-full block paddines_link cursor-pointer text-left">
          Políticas de privacidad
        </router-link>
        <router-link :to="{ name: 'Terms' }" active-class="text-SFRed" class="w-full block paddines_link cursor-pointer text-left">
          Condiciones de Uso y Contratación
        </router-link>
        <router-link :to="{ name: 'cookies-politics' }" active-class="text-SFRed" class="w-full block paddines_link cursor-pointer text-left">
          Politica de Cookies
        </router-link>
      </div>
      <div>
        <a-logo-switch type="1" class="w-40 select-none lowercase inline-block"/>
      </div>
      <div class="w-full bottom-0 border-t border-gray">
        <button v-if="environment" @click="$emit('destroySession')" class="cursor-pointer text-red p-3 w-full text-left">
          <i class="icon-close text-xl pr-sm align-middle" />
          DESTRUIR SESIÓN
        </button>
        <button @click="$emit('closeSession');handleSidebar" class="cursor-pointer p-3 w-full text-left">
          <i class="icon-logout text-xxl pr-sm align-middle" />
          Cerrar sesión
        </button>
      </div>
    </div>
  </aside>
</template>

<script>
import { ref, computed } from 'vue'
import { useSession } from '../../hooks'

export default {
  name: 'sidebar',
  props: {
    show: { required: true, type: Boolean, default: false },
    profile: { required: false, type: Object },
    user: { required: false, type: Object },
    environment: { required: false, type: Boolean },
    missingValues: { required: false },
    loading: { required: false, type: Boolean, default: false },
    stripeLinkError: { required: false, default: false }
  },
  emits: ['update:show', 'closeSession', 'goToSololink', 'destroySession', 'sendInvitation', 'handleSubscriptionLink'],
  setup (props, { emit }) {
    const showSidebar = ref(false)
    const { user: userSession } = useSession()

    const handleSidebar = () => {
      emit('update:show', !props.show)
    }

    const mappedBalance = computed(() => {
      return `${(props.profile.balance_e2 / 100).toFixed(0)}€`
    })

    const getUserId = computed(() => {
      return userSession.isRestaurant ? userSession.restaurant.id : userSession.foodie.id
    })

    const mappedPermissions = computed(() => {
      if (userSession.isRestaurant) {
        if (!props.missingValues.userValidated || props.missingValues.userRejected) {
          return true
        }
        if (props.missingValues.haveMissingData) {
          return true
        }
        if (props.profile.skip_trial) {
          return false
        }
        return false
      }
      if (userSession.isFoodie) {
        if (!props.missingValues.userValidated || !props.missingValues.userDiscoverable || props.missingValues.userRejected) {
          return true
        }
        if (!props.profile.ig_username) {
          return true
        }
        return false
      }
      return false
    })
    return {
      showSidebar,
      mappedPermissions,
      userSession,
      mappedBalance,
      // handleRedirect,
      getUserId,
      handleSidebar
    }
  },
  watch: {
    '$route.path' () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition:  opacity 0.3s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.sidebar-i-container{
  padding-bottom: 18px !important;
}
</style>
