export default ({ axios, mapQueryString }) => ({
  login: (body = {}) => axios.post('/login', body),
  facebook: {
    login: body => axios.post('/oauth/fb-login', body),
    checkExistence: accessToken => axios.get(`/oauth/fb-user/${accessToken}`),
    connection: (body, configuration = {}) => axios.post('/oauth/fb-connect', body, configuration)
  },
  emailConfirm: (body = {}) => axios.post('/users/_email-exists', body),
  igUsername: (body = {}) => axios.post('/users/_ig-exists', body),
  logout: () => axios.post('/logout'),
  refreshJwt: () => axios.post('/refresh-jwt'),
  confirmAccount: () => axios.post('/confirm-account'),
  sendPasswordReset: () => axios.post('/send-password-reset'),
  confirmPasswordReset: () => axios.post('/confirm-password-reset')
})
