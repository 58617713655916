
export default ({
  next,
  store: { getters }
}) => {
  const isAuthenticated = getters['session/isAuthenticated']
  if (isAuthenticated) {
    return next({ name: 'collaborations.index' })
  }
  return next()
}
