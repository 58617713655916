/**
 * This is the main router configuracion,
 * this handles every hook of the vue-router environment.
 */

const nextFactory = (context, middleware, index) => {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) {
    return context.next
  }

  return (...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({
      ...context,
      next: nextMiddleware
    })
  }
}

/**
 * implemented at ~/main.js
 */
export default ({
  router,
  store
}) => {
  router.beforeEach((to, from, next) => {
    store.dispatch('vanishErrors')
    store.dispatch('cancelPendingRequest')

    document.title = `${to.meta?.title?.({
      router,
      store,
      lang: store.getters.lang
    }) ?? ''} Solofoodies` + ' - Gastronomía para compartir'

    if (to.meta?.middleware) {
      const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
      const context = {
        to,
        from,
        next,
        router,
        store
      }
      const nextMiddleware = nextFactory(context, middleware, 1)
      return middleware[0]({
        ...context,
        next: nextMiddleware
      })
    }

    return next()
  })
}
