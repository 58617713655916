import moment from 'moment'

moment.locale('es')

export default (val, format = 'YYYY-MM-DD', utc = false, fallback = '-') => {
  const date = utc ? moment(val).utc() : moment(val)
  if (!date.isValid()) {
    return fallback
  }

  return date.format(format)
}
