import { AllowedForTypes, Authenticated, AllowedForPremiums } from '@/router/middlewares'
import { UserTypes } from '@/constants'
// import Explore from '@/views/authenticated/Explore'
// import Show from '@/views/authenticated/Show'
// import ShowProfile from '@/views/authenticated/ShowProfile'
// import EditProfile from '@/views/authenticated/EditProfile'
// import EditProfileSecurity from '@/views/authenticated/EditProfileSecurity'
// import Favorites from '@/views/authenticated/Favorites'
// import Interested from '@/views/authenticated/restaurants/Interested'
// import foodieProfile from '@/views/authenticated/register/foodie/profile'
// import restaurantProfile from '@/views/authenticated/register/restaurant/profile'
// import restaurantDirection from '@/views/authenticated/register/restaurant/direction'
// import registerComplete from '@/views/authenticated/register/complete'
// import registerUser from '@/views/authenticated/register/user'
// import Notifications from '@/views/authenticated/Notifications'
// import Historial from '../../views/authenticated/Historial'
// import Subscription from '../../views/authenticated/subscription/Subscription'
// import EditSubscription from '../../views/authenticated/subscription/edit-billing'
// import ChangePlan from '../../views/authenticated/subscription/change-plan'
// import Connection from '../../views/authenticated/unconnected/Connection'

export default [
  {
    path: '/explore',
    name: 'Explore',
    component: () => import('../../views/authenticated/Explore'),
    meta: {
      middleware: Authenticated
    }
  },
  {
    path: '/explore/:id',
    name: 'explore.show',
    component: () => import('../../views/authenticated/Show'),
    meta: {
      middleware: Authenticated
    }
  },
  {
    path: '/favorites',
    name: 'explore.favorites',
    component: () => import('../../views/authenticated/Favorites'),
    meta: {
      middleware: Authenticated
    }
  },
  {
    path: '/interested',
    name: 'explore.interested',
    component: () => import('../../views/authenticated/restaurants/Interested'),
    meta: {
      middleware: [AllowedForTypes([UserTypes.restaurant])]
    }
  },
  {
    path: '/register/foodie/profile',
    name: 'register.foodieProfile',
    component: () => import('../../views/authenticated/register/foodie/profile'),
    meta: {
      middleware: Authenticated
    }
  },
  {
    path: '/register/restaurant/profile',
    name: 'register.restaurantProfile',
    component: () => import('../../views/authenticated/register/restaurant/profile'),
    meta: {
      middleware: Authenticated
    }
  },
  {
    path: '/register/restaurant/direction',
    name: 'register.restaurantDirection',
    component: () => import('../../views/authenticated/register/restaurant/direction'),
    meta: {
      middleware: Authenticated
    }
  },
  {
    path: '/register/complete',
    name: 'register.complete',
    component: () => import('../../views/authenticated/register/complete'),
    meta: {
      middleware: Authenticated
    }
  },
  {
    path: '/register/user',
    name: 'register.user',
    component: () => import('../../views/authenticated/register/user'),
    meta: {
      middleware: Authenticated
    }
  },
  {
    path: '/profile/:id',
    name: 'profile.user',
    component: () => import('../../views/authenticated/ShowProfile'),
    meta: {
      middleware: Authenticated
    }
  },
  {
    path: '/profile/:id/edit',
    name: 'profile.user.edit',
    component: () => import('../../views/authenticated/EditProfile'),
    meta: {
      middleware: Authenticated
    }
  },
  {
    path: '/profile/:id/security',
    name: 'profile.user.security',
    component: () => import('../../views/authenticated/EditProfileSecurity'),
    meta: {
      middleware: Authenticated
    }
  },
  {
    path: '/profile/:id/direction',
    name: 'profile.user.direction',
    component: () => import('../../views/authenticated/EditProfileDirection'),
    meta: {
      middleware: Authenticated
    }
  },
  {
    path: '/notifications',
    name: 'notifications.index',
    component: () => import('../../views/authenticated/Notifications'),
    meta: {
      middleware: Authenticated
    }
  },
  {
    path: '/historial',
    name: 'historial.index',
    component: () => import('../../views/authenticated/Historial'),
    meta: {
      middleware: Authenticated
    }
  },
  {
    path: '/subscription',
    name: 'subscription.index',
    component: () => import('../../views/authenticated/subscription/Subscription'),
    meta: {
      middleware: [AllowedForTypes([UserTypes.restaurant])]
    }
  },
  {
    path: '/subscription/:userId/edit-billing-information',
    name: 'subscription.billing.edit',
    component: () => import('../../views/authenticated/subscription/edit-billing'),
    meta: {
      middleware: [AllowedForTypes([UserTypes.restaurant])]
    }
  },
  {
    path: '/subscription/:userId/change-plan',
    name: 'subscription.plan.change',
    component: () => import('../../views/authenticated/subscription/change-plan'),
    meta: {
      middleware: [AllowedForTypes([UserTypes.restaurant])]
    }
  },
  {
    path: '/training',
    name: 'training.index',
    component: () => import('../../views/authenticated/training')
  },
  {
    path: '/stripe-callback',
    redirect: '/'
  },
  // {
  //   path: '/testing',
  //   name: 'testing.index',
  //   component: () => import('../../views/authenticated/foodies/profile/premium/index'),
  //   meta: {
  //     middleware: Authenticated
  //   }
  // },
  {
    path: '/rates',
    name: 'rates.index',
    meta: {
      middleware: AllowedForPremiums
    },
    component: () => import('../../views/authenticated/foodies/rates/index')
  },
  {
    path: '/balance',
    name: 'balance.index',
    component: () => import('../../views/authenticated/foodies/balance/index'),
    meta: {
      middleware: [AllowedForTypes([UserTypes.foodie])]
    }
  }
]
