<template>
  <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
  -->
  <transition enter-active-class="transition-all duration-200 ease-out" leave-active-class="transition-all duration-750 ease-in" enter-class="opacity-0 scale-75" enter-to-class="opacity-100 scale-100" leave-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-75">
    <div class="fixed z-100 inset-0 overflow-y-auto" v-if="show" style="margin-top: 0 !important;">
      <div :class="customClass ? customClass : 'px-10'" class="flex items-center justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
        Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="ease-out duration-300 fixed inset-0 transition-opacity">
          <div @click="disabledOverlayDismiss ? null : $emit('cancel')" :class="['absolute inset-0 backdrop-blur-5 bg-opacity-50', backdropBg]"></div>
          <button @click="$emit('cancel')" v-if="!hideTopCancel" class="absolute right-5 top-5 hover:bg-white hover:bg-opacity-50 transition-colors rounded-full w-10 h-10 flex justify-center items-center focus:outline-none">
            <!-- <v-icon name="plus" class="text-white transform rotate-45" scale="1.3" /> -->
          </button>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <transition enter-active-class="transition-all ease-out duration-300" leave-active-class="ease-in duration-200" enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to-class="opacity-100 translate-y-0 sm:scale-100" leave-class="opacity-100 translate-y-0 sm:scale-100" leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <!-- sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-xl xl:max-w-screen-xl -->
          <div :class="[contentBg, 'inline-block align-bottom rounded-xl text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-full', {
              'sm:max-w-lg md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl': fullScreen,
              'sm:max-w-lg': !fullScreen
          }]" role="dialog" aria-modal="true" aria-labelledby="modal-headline" v-if="show">
            <div :class="[contentBg, 'px-6 pt-3 pb-3 sm:p-6 sm:pb-4 rounded-xl']">
              <div class="sm:flex sm:items-start">
                <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full dark:bg-red-700 dark:bg-opacity-25 bg-red-100 sm:mx-0 sm:h-10 sm:w-10" v-if="mode === 'danger'">
                  <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                </div>
                  <div class="sm:mt-0 sm:text-left w-full" :class="{
                    'sm:ml-4': mode === 'danger'
                  }">
                  <h3 class="text-lg leading-6 dark:text-white font-medium text-gray-900" v-if="title || $slots.title" id="modal-headline">
                    <slot name="title">{{ title }}</slot>
                  </h3>
                  <div :class="{
                    'mt-2': title || $slots.title
                  }">
                    <slot />

                    <div class="mt-8" v-if="secureAction">
                      <div class="flex flex-col">
                        <h2 class="text-lg dark:text-gray-300 inline">Para proceder, por favor ingresa el texto a continuación: <span class="text-gray-800 mt-2 text-md dark:text-gray-500 inline">{{ password }}</span></h2>
                        <!-- <a-input v-model="value" class="mt-2" /> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <slot name="footer">
              <div :class="contentBg" class="px-4 py-3 sm:px-6 flex rounded-b-xl justify-end" v-if="$attrs.onConfirm || $attrs.onCancel">
                <button v-if="!hideCancel || $attrs.onCancel" :class="cancelClass" type="button" class="inline-flex justify-center px-4 py-2 bg-white text-base leading-6 font-medium focus:transition ease-in-out duration-150 sm:text-sm sm:leading-5" @click="$emit('cancel')">
                  {{ cancelText }}
                </button>

                <button v-if="$attrs.onConfirm" type="button" :class="confirmClass" class="inline-flex justify-center px-4 py-2 text-base leading-6 font-medium focus:outline-none disabled:text-gray" @click="$emit('confirm')" :disabled="isDisabledConfirm">
                  <slot name="confirm">
                    <a-loader v-if="loader && loading" :class="loaderStyle"/>
                    <div v-else>{{ confirmText }}</div>
                  </slot>
                </button>
              </div>
            </slot>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: { required: false, type: Boolean, default: false },
    mode: { required: false, type: String, default: 'default' },
    title: { required: false, type: String, default: undefined },
    cancelText: { required: false, type: String, default: 'Cancelar' },
    cancelClass: { required: false, type: String, default: '' }, // 'bg-red-600 hover:bg-red-500' for danger suggestion
    confirmText: { required: false, type: String, default: 'Aceptar' },
    confirmClass: { required: false, type: String, default: 'text-primary' }, // 'bg-red-600 hover:bg-red-500' for danger suggestion
    hideCancel: { required: false, type: Boolean, default: false },
    secureAction: { required: false, type: Boolean, default: false },
    disabledConfirm: { required: false, type: Boolean, default: false },
    backdropBg: { required: false, type: String, default: 'bg-black' },
    contentBg: { required: false, type: String, default: 'bg-white' },
    hideTopCancel: { required: false, type: Boolean, default: false },
    fullScreen: { required: false, type: Boolean, default: false },
    loading: { required: false, type: Boolean, default: false },
    loader: { required: false, type: Boolean, default: false },
    loaderStyle: { required: false, type: String, default: '' },
    disabledOverlayDismiss: { required: false, type: Boolean, default: false },
    customClass: { required: false, type: String, default: '' }
  },
  data: () => ({
    password: '',
    value: ''
  }),
  watch: {
    show () {
      if (this.secureAction) {
        let result = ''
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        const charactersLength = characters.length

        for (let i = 0; i < 12; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength))
        }

        this.password = result
      }
    }
  },
  computed: {
    isDisabledConfirm () {
      let disabled = this.disabledConfirm

      if (this.secureAction) {
        disabled = this.password !== this.value
      }

      return disabled || this.loading
    }
  }
}
</script>
