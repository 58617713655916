export default ({ axios, mapQueryString, withoutInvalidValues, toFormData }) => ({
  index: (query = {}) => axios.get(`/foodies${mapQueryString(query)}`),
  show: (id, query = {}, config = {}) => axios.get(`/foodies/${id}${mapQueryString(query)}`, config),
  store: (body = {}) => axios.post('/foodies', withoutInvalidValues(body)),
  update: (id, body = {}) => axios.patch(`/foodies/${id}`, withoutInvalidValues(body)),
  delete: (id) => axios.delete(`/foodies/${id}`),
  followers: (followers = {}, query = {}) => axios.get(`/foodies${mapQueryString(query)}` + '&ig_followers_count=>' + followers),
  getInterested: (id, query = {}) => axios.get(`/foodies/${id}/interested-restaurants${mapQueryString(query)}`),
  markInterested: (id, body = {}) => axios.post(`/foodies/${id}/interested-restaurants`, body),
  unmarkInterested: (id, restaurant) => axios.post(`foodies/${id}/interested-restaurants/${restaurant}/cancel`),
  getBlacklisted: (id, query = {}) => axios.get(`/foodies/${id}/blacklisted-restaurants${mapQueryString(query)}`),
  setBlacklisted: (id, body) => axios.put(`/foodies/${id}/blacklisted-restaurants`, body),
  collaborations: (id, query = {}, configuration = {}) => axios.get(`/foodies/${id}/collaborations${mapQueryString(query)}`, configuration),
  sololinks: (foodie) => ({
    store: (body) => axios.post(`/foodies/${foodie}/solo-links`, body),
    getSololinks: (restaurant, query = {}) => axios.get(`foodies/${foodie}/restaurants/${restaurant}/solo-links${mapQueryString(query)}`)
  }),
  sendVerifyEmail: (id) => axios.post(`/foodies/${id}/send-ig-verification-mail`),
  products: (foodie) => ({
    index: (query = {}) => axios.get(`/foodies/${foodie}/products${mapQueryString(query)}`),
    show: (id, query = {}) => axios.get(`/foodies/${foodie}/products/${id}${mapQueryString(query)}`),
    store: (body = {}) => axios.post(`/foodies/${foodie}/products`, body),
    update: (id, body = {}) => axios.patch(`/foodies/${foodie}/products/${id}`, body),
    delete: (id, body = {}) => axios.delete(`/foodies/${foodie}/products/${id}`),
    acceptTerms: () => axios.post(`/foodies/${foodie}/accept-premium-terms`)
  })
})
