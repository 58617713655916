import { ref, computed } from 'vue'

const useArray = (array = []) => {
  const state = ref(array)

  const set = (items = []) => {
    state.value = items
  }

  const insert = (at, ...items) => {
    const newArray = [...state.value]
    newArray.splice(at, 0, ...items)
    state.value = newArray
  }

  const append = (...items) => {
    state.value = [...state.value, ...items]
  }

  const prepend = (...items) => {
    state.value = [...items, ...state.value]
  }

  const first = computed(() => state.value[0])

  const last = computed(() => state.value[state.value.length - 1])

  const remove = (index) => {
    const newArray = [...state.value]
    newArray.splice(index, 1)

    state.value = newArray
  }

  const removeFirst = () => {
    const newArray = [...state.value]
    newArray.shift()
    state.value = newArray
  }

  const removeLast = () => {
    const newArray = [...state.value]
    newArray.pop()
    state.value = newArray
  }

  const replace = (index, item) => {
    const newArray = [...state.value]
    newArray.splice(index, 1, item)
    state.value = newArray
  }

  const isEmpty = computed(() => state.value.length === 0)

  const isNotEmpty = computed(() => state.value.length > 0)

  const chunked = (size) => Array.from({ length: Math.ceil(state.value.length / size) }, (_, index) =>
    state.value.slice(index * size, index * size + size)
  )

  const grouping = (byKey) => {
    return state.value.reduce((accumulator, item) => {
      accumulator[item[byKey]] = accumulator[item[byKey]] || []
      accumulator[item[byKey]].push(item)
      return accumulator
    }, {})
  }

  return [state, {
    set,
    insert,
    append,
    prepend,
    first,
    last,
    remove,
    removeFirst,
    removeLast,
    replace,
    isEmpty,
    isNotEmpty,
    chunked,
    grouping
  }]
}

export default useArray
