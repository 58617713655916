<template>
    <div class="h-full w-full inline-block mx-auto text-center">
      <component :is="`${isAuthenticated ? 'authenticated' : 'guest'}-layout`" />
<!--      {{ isAuthenticated ? 'Authenticated' : 'Guest' }}-->
<!--      <component :is="'authenticated-layout'"/>-->
    </div>
    <!-- <button class="fixed right-0 bottom-0 mr-5 mb-5 text-gray-100 dark:text-gray-800 dark:bg-gray-100 bg-gray-800 w-10 h-10 rounded-lg bg-opacity-75 backdrop-blur-15 dark:backdrop-blur-15 dark:bg-opacity-75" @click="toggleTheme(isDarkTheme ? 'light' : 'dark')">
    <v-icon :name="isDarkTheme ? 'sun' : 'moon'" />
    </button> -->
     <div v-if="acceptCookiesPopUp !== 'true'" class="fixed_cookie">
      Al unirte a Solofoodies, confirmas que has leído, entiendes y aceptas nuestras
      <a href="/terms" target="_blank" class="hover:text-primary visited:text-gray" style="font-weight: bold;">
        Condiciones de Uso y Contratación
      </a>,
      <a href="/cookies-politics" target="_blank" class="hover:text-primary visited:text-gray" style="font-weight: bold;">
        Cookies
      </a> y
      <a href="/cookies-politics" target="_blank" class="hover:text-primary visited:text-gray" style="font-weight: bold;">
        Políticas de privacidad
      </a>
      <button class="button uppercase bg-primary text-white items-center" @click="acceptCookies()">
        Acepto
      </button>
    </div>
  <stripejs />
  <google-sdk />
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { AuthenticatedLayout, GuestLayout } from './layouts'
import GoogleSdk from './components/google-sdk'
import Stripejs from './components/stripejs'

export default {
  components: {
    Stripejs,
    AuthenticatedLayout,
    GuestLayout,
    GoogleSdk
  },
  data: () => ({
    acceptCookiesPopUp: 'false',
    isDarkTheme: false,
    profile: {},
    component: {},
    authenticated: ''
  }),
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    })
  },
  methods: {
    acceptCookies () {
      localStorage.setItem('acceptCookiesPopUp', 'true')
      this.acceptCookiesPopUp = localStorage.getItem('acceptCookiesPopUp')
    }
  },
  mounted () {
    this.acceptCookiesPopUp = localStorage.getItem('acceptCookiesPopUp')
    // this.isDarkTheme = localStorage.theme === 'dark'
    // document.body.classList.toggle('dark', this.isDarkTheme)
    // this.acceptCookiesPopUp = localStorage.getItem('acceptCookiesPopUp')
    // // document.body.classList.remove('dark')
    // if (this.isAuthenticated) {
    //   this.$repository.users
    //     .show(this.user.id, {}, { cancelable: false })
    //     .then((response) => {
    //       this.profile = response.data
    //       this.component = this.layout
    //     })
    // }
  }
}
</script>

<style lang="scss" scoped>
  .fixed_cookie{
    background: rgba(0, 0, 0, 0.6) none repeat scroll 0% 0%;
    display: inline-block;
    align-content: center;
    width: 100%;
    bottom: 0px;
    left: 0px;
    z-index: 100;
    text-align: center;
    line-height: 48px;
    color: white;
    position:fixed;
    line-height: 1.7;
    padding: 10px;
    min-height: 48px;
  }
  .button{
    max-width: 140px;
    margin-left: 10px;
    @media screen and (max-width: 1137px){
      margin: 10px auto;
      display:block;
    }
  }
</style>
