<template>
  <div class="space-y-6 flex flex-col text-gray-500">
    <Logo class="self-center text-left mt-8 h-16 cursor-pointer" @click="$router.go(-1)"/>

    <p class="mx-8 font-light">Añade tus datos de facturación</p>

    <div class="flex flex-col w-full px-4 text-black">
      <input
        type="text"
        placeholder="Razón social *"
        :value="business_name"
        @input="$emit('update:business_name', $event.target.value)"
        class="border rounded-md px-4 py-2 w-full">
    </div>

    <div class="flex flex-col w-full px-4 text-black">
      <input
        type="text"
        placeholder="CIF *"
        v-model="inputCif"
        @input="validateCif($event)"
        class="border rounded-md px-4 py-2 w-full uppercase">
    </div>

    <div class="text-center custom-max-w mx-auto">
      <div class="space-x-2">
        <input type="checkbox" id="terms" v-model="haveSameAddress" @change="$emit('update:same_address', $event.target.checked)">
        <label for="terms">
          Mi dirección de facturación es la misma que la dirección de mi restaurante
        </label>
      </div>
    </div>

<!--    <div class="flex flex-col w-full px-4 text-black">-->
<!--      <input-->
<!--        type="text"-->
<!--        :value="billing_cif"-->
<!--        :disabled="same_address"-->
<!--        placeholder="CIF *"-->
<!--        @input="$emit('update:billing_cif', $event.target.value)"-->
<!--        class="border rounded-md px-4 py-2 w-full transition-all ease-in-out duration-300 disabled:bg-gray_light">-->
<!--    </div>-->
    <div class="flex flex-col w-full px-4 text-black">
      <input
        type="text"
        :value="billing_address"
        :disabled="same_address"
        placeholder="Dirección *"
        @input="$emit('update:billing_address', $event.target.value)"
        class="border rounded-md px-4 py-2 w-full transition-all ease-in-out duration-300 disabled:bg-gray_light">
    </div>
    <div class="flex w-full px-4 text-black">
      <input
        type="text"
        :value="billing_city"
        :disabled="same_address"
        placeholder="Ciudad"
        @input="$emit('update:billing_city', $event.target.value)"
        class="border rounded-md px-4 py-2 mr-2 flex-1 transition-all ease-in-out duration-300 disabled:bg-gray_light">
<!--      <input-->
<!--        type="text"-->
<!--        value="Madrid"-->
<!--        disabled-->
<!--        placeholder="Estado/Provincia/Región"-->
<!--        @input="$emit('update:billing_state', $event.target.value)"-->
<!--        class="border rounded-md px-4 py-2 ml-2 flex-1 transition-all ease-in-out duration-300 disabled:bg-gray_light">-->
      <select id="companionSelectorSS" class="flex-1 border ml-2 p-1 rounded px-2" :disabled="same_address" v-model="bstate" @change="$emit('update:billing_state', $event.target.value)">
        <option value="Madrid">Madrid</option>
        <option value="Barcelona">Barcelona</option>
        <option value="Sevilla">Sevilla</option>
        <option value="Bilbao">Bilbao</option>
        <option value="Málaga">Málaga</option>
      </select>
    </div>
    <div class="flex w-full px-4 text-black">
      <input
        type="number"
        :value="billing_zip_code"
        :disabled="same_address"
        placeholder="Código postal"
        @input="$emit('update:billing_zip_code', $event.target.value)"
        class="border rounded-md px-4 py-2 mr-2 flex-1 transition-all ease-in-out duration-300 disabled:bg-gray_light">
      <input
        disabled
        type="text"
        value="España"
        class="border rounded-md px-4 py-2 ml-2 flex-1 disabled:bg-gray_light">
    </div>
    <div v-if="confirmation" class="text-red text-left">
      Por favor Agregue la dirección
    </div>
  </div>
</template>

<script>
import Logo from '@/components/logos/logo-1'

export default {
  name: 'step-8',
  components: {
    Logo
  },
  data () {
    return {
      haveSameAddress: false,
      inputCif: '',
      bstate: ''
    }
  },
  emits: ['update:billing_cif', 'update:billing_address', 'update:billing_city', 'update:billing_state', 'update:billing_zip_code', 'update:same_address', 'update:business_name', 'update:cif'],
  props: {
    cif: { required: false, type: String, default: '' },
    billing_cif: { required: false, type: String, default: '' },
    billing_address: { required: false, type: String, default: '' },
    billing_city: { required: false, type: String, default: '' },
    billing_state: { required: false, type: String, default: '' },
    billing_zip_code: { required: false, type: String, default: '' },
    business_name: { required: false, type: String, default: '' },
    same_address: { required: false, type: Boolean, default: false },
    address: { required: false, type: Object },
    confirmation: { required: false, type: Boolean, default: false }
  },
  methods: {
    validateCif (event) {
      const oldValue = this.cif
      const newValue = event.target.value
      const regexLetter = new RegExp(/^[a-zA-Z]{1}/)

      switch (newValue.length) {
        case 0: {
          this.inputCif = ''
          this.$emit('update:cif', '')
          this.$emit('update:billing_cif', '')
          break
        }
        case 1: {
          if (regexLetter.test(newValue)) {
            this.$emit('update:cif', newValue)
            this.$emit('update:billing_cif', newValue)
            this.inputCif = newValue
          } else {
            this.$emit('update:cif', '')
            this.oldValue = ''
            this.inputCif = ''
          }
          break
        }
        default: {
          if (newValue.length < 9) {
            // if (/[\x30-\x39]\b/.test(newValue)) {
            if (/[0-9]$/.test(newValue)) {
              this.$emit('update:cif', newValue)
              this.$emit('update:billing_cif', newValue)
              this.inputCif = newValue
            } else {
              this.$emit('update:cif', oldValue)
              this.$emit('update:billing_cif', oldValue)
              this.inputCif = oldValue
            }
          } else if (newValue.length === 9) {
            // if (/[\x30-\x39]\b/.test(newValue)) {
            if (/[a-zA-Z0-9]$/.test(newValue)) {
              this.$emit('update:cif', newValue)
              this.$emit('update:billing_cif', newValue)
              this.inputCif = newValue
            } else {
              this.$emit('update:cif', oldValue)
              this.$emit('update:billing_cif', oldValue)
              this.inputCif = oldValue
            }
          } else {
            this.$emit('update:cif', oldValue)
            this.$emit('update:billing_cif', oldValue)
            this.inputCif = oldValue
          }
        }
      }
    },
    handleDirection (event) {
      this.$emit('update:same_address', event.target.checked)
      if (this.same_address) {
        this.$emit('update:billing_address', this.address.address)
        this.$emit('update:billing_city', this.address.city)
        this.$emit('update:billing_state', this.address.state)
        this.$emit('update:billing_zip_code', this.address.zip_code)
        this.bstate = this.address.state
      } else {
        this.$emit('update:billing_address', '')
        this.$emit('update:billing_city', '')
        this.$emit('update:billing_state', '')
        this.$emit('update:billing_zip_code', '')
      }
    }
  },
  mounted () {
    this.haveSameAddress = this.same_address
    this.inputCif = this.cif
    this.$emit('update:same_address', true)
    this.bstate = this.address.state
  },
  watch: {
    same_address (value) {
      this.haveSameAddress = value
      if (value) {
        this.$emit('update:billing_cif', this.cif)
        this.$emit('update:billing_address', this.address.address)
        this.$emit('update:billing_city', this.address.city)
        this.$emit('update:billing_state', this.address.state)
        this.$emit('update:billing_zip_code', this.address.zip_code)
      } else {
        this.$emit('update:billing_cif', this.cif)
        this.$emit('update:billing_address', '')
        this.$emit('update:billing_city', '')
        this.$emit('update:billing_state', '')
        this.$emit('update:billing_zip_code', '')
      }
    }
  }
}
</script>

<style scoped>

</style>
