<template>
  <div />
</template>

<script>
import { onMounted } from 'vue'
export default {
  setup () {
    onMounted(() => {
      if (process.env.VUE_APP_NODE_ENV === 'production') {
        const script = document.createElement('script')
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-ZQX22N4Y5T'
        window.dataLayer = window.dataLayer || []

        function gtag () { // eslint-disable-line no-inner-declarations
          dataLayer.push(arguments) // eslint-disable-line no-undef
        }

        gtag('js', new Date())
        gtag('config', 'G-ZQX22N4Y5T')
        document.body.appendChild(script)
      }
    })
  }
}
</script>

<style scoped>

</style>
