import { UserTypes } from '@/constants'

const configureUser = user => {
  const type = user?.type ?? user?.user?.type
  return {
    ...user,
    isAdmin: type === UserTypes.admin,
    isRestaurant: type === UserTypes.restaurant,
    isFoodie: type === UserTypes.foodie
  }
}

export default {
  namespaced: true,
  state: {
    user: localStorage.getItem(process.env.VUE_APP_USER_STORE_KEY)
      ? configureUser(JSON.parse(localStorage.getItem(process.env.VUE_APP_USER_STORE_KEY)))
      : {},
    transitioning: false,
    loading: {
      send: false,
      confirm: false
    },
    showLoginModal: false,
    temporalFacebookLogin: null
  },
  mutations: {
    SET_USER (state, user) {
      state.user = user
    },
    TOGGLE_SESSION_TRANSITION (state) {
      state.transitioning = !state.transitioning
      return state
    },
    TOGGLE_LOADING (state, type) {
      state.loading[type] = !state.loading[type]
      return state
    },
    TOGGLE_LOGIN_MODAL (state) {
      state.showLoginModal = !state.showLoginModal
      return state
    },
    SET_TEMPORAL_FB_LOGIN (state, authResponse) {
      state.temporalFacebookLogin = authResponse
    }
  },
  actions: {
    login ({ commit, dispatch }, user) {
      commit('TOGGLE_SESSION_TRANSITION')
      return this.$repository
        .session
        .login(user)
        .then(({ data }) => {
          dispatch('configure', { session: data })
          return configureUser(data.user)
        })
        .finally(() => commit('TOGGLE_SESSION_TRANSITION'))
    },
    facebookLogin ({ commit, dispatch }, body) {
      commit('TOGGLE_SESSION_TRANSITION')
      return this.$repository
        .session
        .facebook
        .login(body)
        .then(({ data }) => {
          dispatch('configure', { session: data })
          return configureUser(data.user)
        })
        .finally(() => commit('TOGGLE_SESSION_TRANSITION'))
    },
    facebookConnect ({ commit, dispatch }, { body = {}, resolveSession = () => ({}), configuration = {} } = {}) {
      commit('TOGGLE_SESSION_TRANSITION')
      return this.$repository.session
        .facebook
        .connection(body, configuration)
        .then(({ data }) => {
          const session = resolveSession(data)
          dispatch('configure', { session })
          return configureUser(session.user)
        })
        .finally(() => commit('TOGGLE_SESSION_TRANSITION'))
    },
    logout ({ commit, dispatch }) {
      commit('TOGGLE_SESSION_TRANSITION')
      return this.$repository
        .session
        .logout()
        .finally(() => {
          commit('TOGGLE_SESSION_TRANSITION')
          dispatch('configure', {})
          commit('SET_TEMPORAL_FB_LOGIN', null)
        })
    },
    vanish ({ commit }) {
      commit('SET_USER', {})
      localStorage.removeItem(process.env.VUE_APP_USER_STORE_KEY)
      localStorage.removeItem('accesstoken')
      // this.$router.push({ name: 'Login' })
    },
    configure ({ commit }, {
      session,
      namespace
    } = undefined) {
      if (namespace) {
        if (session[namespace]) {
          localStorage.setItem(process.env.VUE_APP_USER_STORE_KEY, JSON.stringify(session[namespace]))
          commit('SET_USER', configureUser(session[namespace]))
        }

        if (session?.access_token) {
          localStorage.setItem(process.env.VUE_APP_ACCESS_TOKEN_STORE_KEY, session.access_token)
        }

        if (!session?.access_token && !session[namespace]) {
          localStorage.removeItem(process.env.VUE_APP_ACCESS_TOKEN_STORE_KEY)
          localStorage.removeItem(process.env.VUE_APP_USER_STORE_KEY)
          commit('SET_USER', {})
        }
      } else {
        if (session?.user) {
          localStorage.setItem(process.env.VUE_APP_USER_STORE_KEY, JSON.stringify(session.user))
          commit('SET_USER', configureUser(session.user))
        }

        if (session?.restaurant) {
          let mappedRestaurant = {}
          const restaurant = session.restaurant
          const user = session.restaurant.user
          delete restaurant.user
          mappedRestaurant = { ...user, restaurant: restaurant }
          localStorage.setItem(process.env.VUE_APP_USER_STORE_KEY, JSON.stringify(mappedRestaurant))
          commit('SET_USER', configureUser(mappedRestaurant))
        }

        if (session?.access_token) {
          localStorage.setItem(process.env.VUE_APP_ACCESS_TOKEN_STORE_KEY, session.access_token)
        }
      }

      if (!session && !namespace) {
        localStorage.removeItem(process.env.VUE_APP_ACCESS_TOKEN_STORE_KEY)
        localStorage.removeItem(process.env.VUE_APP_USER_STORE_KEY)
        commit('SET_USER', {})
      }
    },
    confirm ({ commit }, body) {
      commit('TOGGLE_LOADING', 'confirm')
      return this.$repository.users
        .confirmAccount(body)
        .finally(() => commit('TOGGLE_LOADING', 'confirm'))
    },
    sendPasswordReset ({ commit }, email) {
      commit('TOGGLE_LOADING', 'send')
      return this.$repository.users
        .sendPasswordReset(email)
        .finally(() => commit('TOGGLE_LOADING', 'send'))
    },
    setNewToken (_, token) {
      localStorage.setItem(process.env.VUE_APP_ACCESS_TOKEN_STORE_KEY, token)
    },
    setNewUser ({ dispatch }, data) {
      dispatch('configure', { session: data })
      return configureUser(data.restaurant)
    }
  },
  getters: {
    isAuthenticated: ({ user }) => user && Object.keys(user).length > 0,
    isTransitioningSession: ({ transitioning }) => transitioning
  }
}
