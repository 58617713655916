export default ({ axios, mapQueryString, withoutInvalidValues, toFormData }) => ({
  index: (query = {}) => axios.get(`/bookings${mapQueryString(query)}`),
  show: (id, query = {}, config = {}) => axios.get(`/bookings/${id}${mapQueryString(query)}`, config),
  update: (id, body = {}) => axios.patch(`/bookings/${id}`, withoutInvalidValues(body)),
  cancel: (id) => axios.patch(`/bookings/${id}/cancel`),
  delete: (id) => axios.delete(`/bookings/${id}`),
  verifyAvailability: (body = {}) => axios.post('/bookings/verify-availability', body),
  dateAvailability: (body = {}) => axios.post('/bookings/date-availability', body),
  availableDates: (body = {}) => axios.post('/bookings/available-dates', body),
  sololinks: sololinkId => ({
    store: (body = {}) => axios.post(`/solo-links/${sololinkId}/bookings`, body)
  }),
  foodies: foodieId => ({
    store: (body = {}) => axios.post(`/foodies/${foodieId}/bookings`, withoutInvalidValues(body))
  })
})
