import { AllowedForTypes, OnlyPublic } from '@/router/middlewares'
import { UserTypes } from '@/constants'

export default [
  {
    path: '/:igusername',
    name: 'sololink.index',
    component: () => import('../../views/public/sololink/index'),
    meta: {
      middleware: OnlyPublic
    }
  },
  {
    path: '/sololink/edit',
    name: 'sololink.edit',
    component: () => import('../../views/public/sololink/edit'),
    meta: {
      middleware: AllowedForTypes([UserTypes.foodie])
    }
  },
  {
    path: '/:igusername/reserva/:restigusername',
    name: 'sololink.reservation',
    component: () => import('../../views/public/sololink/reservation'),
    meta: {
      middleware: OnlyPublic
    }
  },
  {
    // path: '/:igusername/reseva/:restigusername/external',
    path: '/:igusername/reserva/:restigusername/external',
    name: 'sololink.external',
    component: () => import('../../views/public/sololink/external'),
    meta: {
      middleware: OnlyPublic
    }
  }
]
