export default ({
  axios,
  withoutInvalidValues,
  mapQueryString,
  toFormData
}) => ({
  /**
   * @description expecting something like {
   *      "new_email": "required|email",
   *      "password": "required|string" -> current user password
   * }
   */
  updateEmail: (id, body) => axios.patch(`/users/${id}/change-email`, body),
  /**
   * @description expecting something like {
   *      "password": "required|string", -> current user password
   *      "new_password": "required|string",
   *      "new_password_confirmation": "required|string|same:new_password"
   * }
   */
  updatePassword: (id, body) => axios.patch(`/users/${id}/change-password`, body),

  sendPasswordReset: (email) => axios.post(`/send-password-reset/${email}`),
  /**
   * @description expecting something like {
   *     "token": "required|string",
   *     "email": "required|string"
   * }
   */
  confirmAccount: (body) => axios.post('/confirm-account', body),
  /**
   * @description expecting something like {
   *     "enabled": "required|boolean",
   * }
   */
  update: (id, body) => axios.patch(`/users/${id}`, toFormData(body)),

  /**
   * DANGER!! this endpoint will destroy the api user.
   * @param {*} user
   * @returns
   */
  dangerousDestroy: (user) => axios.post(`/debug/destroy-user/${user}`),

  recoverPassword: (body) => axios.post('/confirm-password-reset', withoutInvalidValues(body)),
  show: (id, query = {}, config = {}) => axios.get(`/users/${id}${mapQueryString(query)}`, config),
  igUsername: (igUsername) => axios.get(`users/ig/${igUsername}`),
  updateDiscovered: (id) => axios.post(`users/${id}/update-ig-discoverable`),
  sendVerifyEmail: (id) => axios.post(`/users/${id}/send-ig-verification-mail`)
})
