import axios from 'axios'

import collaborations from './repository/collaborations'
import foodies from './repository/foodies'
import invitations from './repository/invitations'
import notifications from './repository/notifications'
import restaurants from './repository/restaurants'
import session from './repository/session'
import users from './repository/users'
import bookings from './repository/bookings'
import sololinks from './repository/sololinks'
import stripe from './repository/stripe'
import balance from './repository/balance'

const withoutInvalidValues = body => {
  Object.entries(body)
    .forEach($0 => {
      const value = $0[1]
      const attribute = $0[0]

      if (value === '' || value === undefined || value === null) {
        delete body[$0[0]]
      } else if (typeof value === 'object') {
        if (!Array.isArray(value)) {
          withoutInvalidValues(value)
        } else {
          if (!value.length) {
            delete body[attribute]
          }
        }
      }
    })

  return body
}

const toFormData = body => {
  const data = new FormData()
  Object.entries(body)
    .forEach($0 => {
      if (Array.isArray($0[1])) return $0[1].forEach(($1, key) => data.append(`${$0[0]}[${key}]`, $1))

      if ($0[1] === true) return data.append($0[0], 1)

      if ($0[1] === false) return data.append($0[0], 0)

      // if (Object.isObject($0[1])) return toFormData($0[1]) // EXPERIMENTAL

      data.append($0[0], $0[1])
    })

  return data
}

const mapQueryString = filters => {
  return Object.entries(filters)
    .filter($0 => $0[1] !== '')
    .map(($0, index) => {
      return `${index === 0 ? '?' : '&'}${$0[0]}=${$0[1]}`
        .replaceAll(/\s+/gm, '') // removes all new lines, tabs and spaces
        .trim()
    }).join('')
}

const getToken = () => localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_STORE_KEY)

/**
 *
 * @type {{collaborations, foodies, invitations, notifications, restaurants, session, users, bookings, sololinks}}
 */
const repositories = Object.entries({
  collaborations,
  foodies,
  invitations,
  notifications,
  restaurants,
  session,
  users,
  bookings,
  sololinks,
  stripe,
  balance
}).reduce((repository, [attribute, value]) => ({
  ...repository,
  [attribute]: value({ axios, mapQueryString, withoutInvalidValues, toFormData, getToken })
}), {})

export const Repository = {
  install: (app, options = {}) => {
    app.config.globalProperties.$repository = repositories
    app.config.globalProperties.$queryUtils = {
      mapQueryString,
      withoutInvalidValues
    }

    if (options.store) {
      options.store.$repository = repositories
    }
  }
}

// eslint-disable-next-line no-extend-native
Promise.prototype._loading = function (loading = () => {
}) {
  loading()
  return Promise.prototype.finally.apply(this, [() => {
    loading()
  }])
}

export default repositories
