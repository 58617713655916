<template>
<!--  <div class="flex justify-center">-->
    <div class="flex flex-col justify-center h-full space-y-10 px-4 text-black">
      <Logo class="self-center text-left mb-6 h-16 cursor-pointer w-full"/>

      <div>
        <h1 class="">
          Hemos enviado un correo de verificación a:
        </h1>
        <div class="w-64" />
        <b class="text-xl text-primary inline-block">{{ user?.email }}</b><button @click="modals.email = true" class="inline-block w-16 cursor-pointer text-onboarding_light_gray">Editar</button>
      </div>

      <div class="space-y-4 bg-unconnected_gray rounded-lg p-4 font-bold text-black">
        <div>
          <h1 class="">
            Revisa tu buzón y sigue los pasos para verificar tu cuenta.
          </h1>
        </div>

        <div>
          <h1 class="text-lg">
            Instagram asociado:
          </h1>
          <b class="text-lg text-primary inline-block">@{{ user?.ig_username }}</b><button @click="modals.igUsername = true" class="inline-block w-16 cursor-pointer text-onboarding_light_gray">Editar</button>
        </div>

        <div>
          <b class="text-onboarding_light_gray font-semibold">¿No has recibido el correo?</b>
          <p class="">Revisa tu bandeja de correo no deseado</p>
        </div>
      </div>

      <div class="h-10">
        <a-loader v-if="loading" class="h-8 w-8 block mx-auto" :color="'text-gray'"/>
        <p v-else class="cursor-pointer underline text-xl text-black" @click="resendEmail">Volver a enviar correo</p>
      </div>
      <div>
        <p class="text-lg">¡Te ayudamos! escríbenos a info@solofoodies.com</p>
      </div>
    </div>
<!--  </div>-->
  <a-alert
    :show="modals.email"
    @cancel="modals.email = false"
    @confirm="update_email"
    :loading="loadingEmail"
    :disabledConfirm="!password || existEmail"
    :title="'Editar Email'"
  >
    <!--      <input type="text" v-model="email" class="mt-4"/>-->

    <div
      class="border overflow-hidden rounded-md w-full flex justify-between items-center px-2"
      :class="{
          'border-red-500': existEmail === true,
          'border-green-600': existEmail === false,
          'border-gray': existEmail === null
        }">
      <input
        type="text"
        placeholder="Nuevo email"
        v-model="email"
        v-debounce:500="search => searchEmail(search)"
        class="unstyled flex-grow w-full px-2 -ml-2 py-3 focus:outline-none">
      <a-loader class="w-10 h-6" color="text-gray-400" v-if="loadingEmail" />
      <template v-else-if="existEmail === true">
        <i class="icon-close border p-1 text-sm border-red-500 rounded-full text-red-500" />
      </template>
      <template v-else-if="existEmail === false">
        <i class="icon-checkCircle text-green-500 text-xl mr-1" />
      </template>
    </div>

    <input type="password" v-model="password" Placeholder="contraseña" class="border overflow-hidden rounded-md w-full flex justify-between items-center px-2 mt-2"/>
    <p class="text-sm text-red mt-2" v-show="!password || password === null">Es necesario ingresar su contraseña para poder cambiar su correo</p>
  </a-alert>

  <a-alert
    :show="modals.igUsername"
    @cancel="modals.igUsername = false"
    @confirm="update_ig"
    :loading="loadingIg"
    :disabledConfirm="user.ig_username === ig_username"
    :title="'Editar Instagram Username'"
  >
    <!--      <input type="text" v-model="email" class="mt-4"/>-->

    <div
      class="border overflow-hidden rounded-md w-full flex justify-between items-center px-2"
      :class="{
          'border-red-500': existIg === true,
          'border-green-600': existIg === false,
          'border-gray': existIg === null
        }">
      <input
        type="text"
        placeholder="Nuevo email"
        v-model="ig_username"
        v-debounce:500="search => searchIgUsername(search)"
        class="unstyled flex-grow w-full px-2 -ml-2 py-3 focus:outline-none">
      <a-loader class="w-10 h-6" color="text-gray-400" v-if="loadingIg" />
      <template v-else-if="existIg === true">
        <i class="icon-close border p-1 text-sm border-red-500 rounded-full text-red-500" />
      </template>
      <template v-else-if="existIg === false">
        <i class="icon-checkCircle text-green-500 text-xl mr-1" />
      </template>
    </div>
  </a-alert>
</template>

<script>
import { mapState } from 'vuex'
import Logo from '@/components/logos/logo-1'

export default {
  name: 'waiting',
  components: {
    Logo
  },
  props: {
    user: { required: false, type: Object },
    id: { required: false, type: Number }
  },
  emits: ['refresh'],
  data () {
    return {
      loading: false,
      email: '',
      password: null,
      ig_username: '',
      loadingEmail: false,
      loadingIg: false,
      modals: {
        email: false,
        igUsername: false
      },
      existEmail: null,
      existIg: null
    }
  },
  computed: {
    ...mapState({
      session: ({ session }) => session.user
    })
  },
  methods: {
    resendEmail () {
      this.loading = true
      this.$repository.users
        .sendVerifyEmail(this.user.user_id)
        .finally(() => (this.loading = false))
    },
    update_email () {
      this.loadingEmail = !this.loadingEmail
      this.$repository.users
        .updateEmail(this.user.user_id, {
          password: this.password,
          new_email: this.email
        })
        .then(() => {
          // this.updateDiscover()
          this.$emit('refresh')
        })
        .finally(() => {
          this.modals.email = false
        })
    },
    update_ig () {
      this.loadingEmail = !this.loadingEmail
      if (this.session.isFoodie) {
        this.$repository.foodies
          .update(this.user.id, { ig_username: this.ig_username })
          .then(() => {
            this.$emit('refresh')
          })
          .finally(() => {
            this.modals.igUsername = false
          })
      } else {
        this.$repository.restaurants
          .update(this.user.id, { ig_username: this.ig_username })
          .then(() => {
            this.$emit('refresh')
          })
          .finally(() => {
            this.modals.igUsername = false
          })
      }
    },
    searchEmail (email) {
      this.loadingEmail = true
      this.$repository.session
        .emailConfirm({ email: email })
        .then((response) => {
          this.existEmail = response.data._exists
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.loadingEmail = false
        })
    },
    searchIgUsername (igUsername) {
      this.loadingIg = true
      this.$repository.session
        .igUsername({ ig_username: igUsername })
        .then((response) => {
          this.existIg = response.data._exists
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.loadingIg = false
        })
    }
  },
  mounted () {
    this.resendEmail()
    this.email = this.user.email
    this.ig_username = this.user.ig_username
  }
}
</script>

<style scoped>
* {
  font-family: poppins, sans-serif;
}
</style>
