<template>
  <component :is="name" />
</template>

<script>
import * as bootstrap from './bs'

const ucFirst = value => `${value.charAt(0).toUpperCase()}${value.slice(1)}`

const icons = {
  bs: bootstrap
}

export default {
  props: {
    name: { required: true, type: String }
  },
  components: Object.entries(icons).reduce((a, [attribute, value]) => {
    const components = {}
    Object.entries(value).forEach(([_attribute, _value]) => {
      const component = `${ucFirst(attribute)}${_attribute}`
      if (!(component in components)) {
        components[component] = _value
      }
    })

    return components
  }, {})
}
</script>
