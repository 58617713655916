export default ({ axios, mapQueryString, withoutInvalidValues }) => ({
  index: (query = {}) => axios.get(`/restaurants${mapQueryString(query)}`),
  show: (id, query = {}, config = {}) => axios.get(`/restaurants/${id}${mapQueryString(query)}`, config),
  store: (body = {}) => axios.post('/restaurants', withoutInvalidValues(body)),
  update: (id, body = {}) => axios.patch(`/restaurants/${id}`, body),
  delete: (id) => axios.delete(`/restaurants/${id}`),
  getInterestedFoodies: (id, query = {}) => axios.get(`/restaurants/${id}/interested-foodies${mapQueryString(query)}`),
  getFavorites: (id, query = {}) => axios.get(`/restaurants/${id}/favorite-foodies${mapQueryString(query)}`),
  setFavorite: (id, body = {}) => axios.put(`/restaurants/${id}/favorite-foodies`, body),
  collaborations: (id, query = {}, config = {}) => axios.get(`/restaurants/${id}/collaborations${mapQueryString(query)}`, config),
  // setFavorite: (id, body = {}) => console.log(id + '/' + body.attach + '/' + body.detach),
  // Settings
  getSettings: (id, query = {}) => axios.get(`/restaurants/${id}/settings`),
  setSettings: (id, body = {}) => axios.patch(`/restaurants/${id}/settings`, body)
})
