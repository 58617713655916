import { useRepository } from '../hooks'
import router from '../router'

const repository = useRepository(({ stripe }) => stripe)

class StripeJs {
  constructor (plan) {
    this.plan_id = plan.plan_id
    this.plan_price_id = plan.id
    this.stripePublicKey = process.env.VUE_APP_STRIPE_KEY
    this.stripe = window.Stripe(this.stripePublicKey)
    this.elements = []
    this.loading = false
    this.setupIntent = []
    this.errors = null
    this.modal = false
    this.loadingSubscription = false
    this.performingSubscription = false
  }

  async setupStripe () {
    this.loading = true
    // const stripePublicKey = process.env.VUE_APP_STRIPE_KEY
    // const stripe = window.Stripe(stripePublicKey)

    // const loadingEvent = new CustomEvent('loading-stripe', { loading: true })
    // window.dispatchEvent(loadingEvent)

    const elementsOptions = {
      clientSecret: await this.getSetupIntentToken()
    }
    this.elements = this.stripe.elements(elementsOptions)

    // Create and mount the Payment Element
    const paymentElement = this.elements.create('payment')
    paymentElement.mount('#payment-element')

    // const form = document.getElementById('payment-form')

    // form.addEventListener('submit', async (event) => {
    //   event.preventDefault()
    //   // TODO Cambiar la url de redirección
    //   try {
    //     const result = await stripe.confirmSetup({
    //       // Elements instance that was used to create the Payment Element
    //       elements,
    //       confirmParams: {
    //         return_url: '/explore'
    //       },
    //       redirect: 'if_required'
    //     })
    //
    //     const setupIntent = result.setupIntent
    //
    //     // Your customer will be redirected to your `return_url`. For some payment
    //     // methods like iDEAL, your customer will be redirected to an intermediate
    //     // site first to authorize the payment, then redirected to the `return_url`.
    //     console.log('Good to go!')
    //     // console.log(setupIntent);
    //
    //     if (confirm('Perform subscription?')) {
    //       console.log('Performing subscription')
    //
    //       await this.performSubscription(setupIntent.payment_method)
    //     }
    //   } catch (err) {
    //     // This point will only be reached if there is an immediate error when
    //     // confirming the payment. Show error to your customer (for example, payment
    //     // details incomplete)
    //     const messageContainer = document.querySelector('#error-message')
    //     console.log(err)
    //     messageContainer.textContent = err.message
    //   }
    // })
  }

  async getSetupIntentToken () {
    this.loading = true
    let res = {}

    await repository.prepare()
      .then(({ data: response }) => {
        res = response.stripe_client_secret
      })
      .finally(() => {
        this.loading = false
      })

    // const loadingEvent = new CustomEvent('loading-stripe', false)
    // window.dispatchEvent(loadingEvent)
    return res
  }

  async submitSubscription (elements) {
    this.loadingSubscription = true
    await this.stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: window.location.href
      },
      redirect: 'if_required'
    }).then((res) => {
      if (res.error) {
        this.errors = res.error
      } else {
        this.setupIntent = res.setupIntent
        // this.modal = true
      }
    }).finally(() => {
      this.loadingSubscription = false
    })
  }

  async performSubscription (paymentMethod) {
    this.performingSubscription = true
    this.errors = null
    await repository.perform({ plan_id: this.plan_id, plan_price_id: this.plan_price_id, stripe_payment_method: paymentMethod })
      .then(() => {
        router.go()
      })
      .catch((err) => {
        this.errors = err.error
      })
      .finally(() => {
        this.performingSubscription = false
        // this.modal = true
      })
  }
}
export default StripeJs
