<template>
  <div class="relative inline-block">

    <transition name="fade">
      <div class="fixed bg-black bg-opacity-25 z-30 inset-0" v-if="open" @click="handleOpen" />
    </transition>

    <button class="relative flex items-center focus:outline-none select-none cursor-pointer" @click.stop="handleOpen" type="button">
      <slot name="trigger">
        <span class="h-8 w-8 cursor-pointer rounded-full flex justify-center items-center">
          <i class="icon-sort text-gray-500 text-xl" />
        </span>
      </slot>
    </button>

    <transition enter-active-class="transition-all duration-200 ease-out" leave-active-class="transition-all duration-200 ease-in" enter-class="opacity-0 scale-75" enter-to-class="opacity-100 scale-100" leave-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-75">
      <div class="absolute shadow-lg w-48 rounded-lg py-1 px-2 text-sm mt-1 dark:bg-gray-900 bg-white z-40" :class="placement === 'right' ? 'right-0' : 'left-0'" v-if="open" @click="handleOpen">
        <slot v-bind="{ isOpen: open, toggle: handleOpen }"/>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
export default {
  props: {
    placement: {
      type: String,
      default: 'right',
      validator: (value) => ['right', 'left'].indexOf(value) !== -1
    }
  },
  setup () {
    const open = ref(false)

    const handleOpen = () => {
      open.value = !open.value
      document.documentElement?.click?.() // eslint-disable-line no-unused-expressions
    }

    const onEscape = (e) => {
      if (e.key === 'Esc' || e.key === 'Escape') {
        open.value = false
      }
    }

    onMounted(() => {
      document.addEventListener('keydown', onEscape)
    })

    onUnmounted(() => {
      document.removeEventListener('keydown', onEscape)
    })

    const test = () => {
      console.log('test')
    }

    return {
      open,
      handleOpen,
      test
    }
  }
}
</script>
