import {
  AllowedForTypes,
  Authenticated
} from '@/router/middlewares'
import { UserTypes } from '@/constants'
// import Index from '@/views/authenticated/Collaboration'
// import OpenFoodies from '@/views/authenticated/foodies/collaboration/index/open-collaborations'
// import PrivateFoodies from '@/views/authenticated/foodies/collaboration/index/private-collaborations'
// import ConfirmedFoodies from '@/views/authenticated/foodies/collaboration/index/confirmed-collaborations'
// import Show from '@/views/authenticated/CollaborationShow'
// import ShowAction from '@/views/authenticated/foodies/collaboration/show'
// import Private from '@/views/authenticated/restaurants/collaboration/Privates'
// import CollabRestPublic from '@/views/authenticated/restaurants/collaboration/Index'
// import CollabCreate from '@/views/authenticated/restaurants/collaboration/new/collaborationPrivate'
// ----- OLD PRIVATE COLLAB CREATE -----
// import CollabCreate from '@/views/authenticated/restaurants/collaborationCreate'
// ---------------------------------
// import CollabCreateMulti from '@/views/authenticated/restaurants/collaborationCreateMulti'
// import CollabCreatePublic from '@/views/authenticated/restaurants/collaborationCreatePublic'

// import CollabTest from '@/views/authenticated/restaurants/collaboration/new/collaborationPrivate'
// import CollabStepCreate from '@/views/authenticated/restaurants/collaboration/new/collaborationCreate'

export default [
  {
    path: '/collaborations',
    alias: '/collaboration',
    name: 'collaborations.index',
    component: () => import('../../views/authenticated/Collaboration'),
    meta: {
      middleware: [AllowedForTypes([UserTypes.foodie, UserTypes.restaurant])]
    }
  },
  {
    path: '/collaborations/:id',
    name: 'collaborations.show',
    component: () => import('../../views/authenticated/CollaborationShow'),
    meta: {
      middleware: [Authenticated]
    }
  },
  {
    path: '/collaborations/:id/:type/action',
    name: 'collaborations.action',
    component: () => import('../../views/authenticated/foodies/collaboration/show'),
    meta: {
      middleware: Authenticated
    }
  },
  {
    path: '/collaboration/create/:id',
    name: 'collaboration.create',
    component: () => import('../../views/authenticated/restaurants/collaboration/new/collaborationPrivate'),
    meta: {
      middleware: [AllowedForTypes([UserTypes.restaurant])]
    }
  },
  // {
  //   path: '/collaboration/create/multi',
  //   name: 'collaboration.create.multi',
  //   component: CollabCreateMulti,
  //   meta: {
  //     middleware: [AllowedForTypes([UserTypes.restaurant])]
  //   }
  // },
  {
    path: '/collaboration/create',
    name: 'collaboration.create.public',
    component: () => import('../../views/authenticated/restaurants/collaboration/new/collaborationCreate'),
    meta: {
      middleware: [AllowedForTypes([UserTypes.restaurant])]
    }
  },
  {
    path: '/collaboration/create/:id/premium',
    name: 'collaboration.create.premium',
    component: () => import('../../views/authenticated/restaurants/collaboration/v3/collaborationPremium'),
    meta: {
      middleware: [AllowedForTypes([UserTypes.restaurant])]
    }
  }
  // {
  //   path: '/collaboration/create/:id',
  //   name: 'collaboration.create',
  //   component: CollabCreate,
  //   meta: {
  //     middleware: [AllowedForTypes([UserTypes.restaurant])]
  //   }
  // },
  // {
  //   path: '/steps',
  //   name: 'steps',
  //   component: CollabStepCreate,
  //   meta: {
  //     middleware: [AllowedForTypes([UserTypes.restaurant])]
  //   }
  // },
  // {
  //   path: '/colabsteps',
  //   name: 'colab.steps',
  //   component: CollabStepCreate,
  //   meta: {
  //     middleware: [AllowedForTypes([UserTypes.restaurant])]
  //   }
  // }
  // {
  // path: '/collaboration',
  // component: Index,
  // meta: {
  //   middleware: [AllowedForTypes([UserTypes.restaurant])]
  // }

  // children: [
  //   {
  //     path: '',
  //     name: 'collaborations.restaurant.public',
  //     component: CollabRestPublic,
  //     meta: {
  //       middleware: [AllowedForTypes([UserTypes.restaurant])]
  //     }
  //   },
  //   {
  //     path: '/private',
  //     name: 'collaborations.restaurant.private',
  //     component: Private,
  //     meta: {
  //       middleware: [AllowedForTypes([UserTypes.restaurant])]
  //     }
  //   }
  // ]
  // }
]
