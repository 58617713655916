<template>
  <div class="flex items-center justify-center flex-shrink-0 text-center bg-dgray text-white rounded-full overflow-hidden" :class="desingClass">
    <img
      v-if="src && !hasError"
      :src="src"
      @error="hasError = true"
      class="w-full h-full" />
    <span v-else class="flex-auto text-default-xl">
      {{ initials }}
    </span>
  </div>
</template>

<script>
import { computed, defineComponent, toRefs, ref } from 'vue'

export default defineComponent({
  props: {
    name: { required: false, type: String, default: '' },
    src: { required: false, type: String, default: null },
    desingClass: { required: false, type: String, default: 'custom-text-width' },
    initialLenght: { required: false, type: Number, default: 2 }
  },
  setup (props) {
    const { name, initialLenght } = toRefs(props)
    const hasError = ref(false)

    const initials = computed(() => {
      return name.value
        .split(' ')
        .map(string => string.charAt(0).toUpperCase())
        .slice(0, initialLenght.value)
        .join('')
    })

    return {
      initials,
      hasError
    }
  }
})
</script>
<style scoped>
.custom-text-width{
  min-width: 45px;
  min-height: 45px;
  height: 45px;
  width: 45px;
}
</style>
