<template>
  <div class="relative h-full guest-layout">
    <!--    SIDE BAR -->
    <div v-if="loadingPage" class="lds-dual-ring"/>
    <div v-else>
      <sidebar
        v-model:show="showSidebar"
        :profile="profile"
        :user="userValues"
        :environment="environment"
        :missingValues="{userValidated, userDiscoverable, userRejected, current_plan, billingAddress, haveMissingData, isAllStars}"
        :loading="loadingLink"
        :stripeLinkError="stripeLinkError"
        @destroySession="destroySessionWithUser()"
        @sendInvitation="sendInvitation()"
        @goToSololink="goToSololink()"
        @handleSubscriptionLink="handleSubscriptionLink"
        @closeSession="adviced = !adviced"
      />
      <!-- SIDEBAR -->
<!--      <aside-->
<!--        class="bg-black bg-opacity-50 inset-0 z-80"-->
<!--        v-if="mappedPermissions">-->

<!--        <transition name="fade">-->
<!--          <div @click="handleSidebar" class="fixed inset-0 bg-black z-80 bg-opacity-50" v-if="showSidebar" />-->
<!--        </transition>-->

<!--        <div class="flex flex-col fixed w-56 bg-white right-0 z-80 inset-y-0 transition-transform transform" :class="{-->
<!--          'translate-x-56': !showSidebar,-->
<!--          'translate-x-0': showSidebar-->
<!--        }">v-->
<!--          <div class="p-normal text-left sidebar-i-container border-b border-gray">-->
<!--            <div>-->
<!--              <a-initials :name="profile.name" :src="profile.profile_picture"></a-initials>-->
<!--              <p class="text-lg font-regular pt-extra">{{ profile.name }}</p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="py-2">-->
<!--            <div class="flex-grow pt-small">-->
<!--              <router-link @click="handleSidebar" to="/politics" class="w-full block paddines_link cursor-pointer text-left">-->
<!--                Políticas de privacidad-->
<!--              </router-link>-->
<!--              <router-link @click="handleSidebar" to="/terms" class="w-full block paddines_link cursor-pointer text-left">-->
<!--                Condiciones de Uso y Contratación-->
<!--              </router-link>-->
<!--              <router-link @click="handleSidebar" to="/cookies-politics" class="w-full block paddines_link cursor-pointer text-left">-->
<!--                Politica de Cookies-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="flex-grow pt-small">-->
<!--          </div>-->
<!--          <div>-->
<!--            <a-logo-switch type="1" class="w-40 select-none lowercase inline-block"/>-->
<!--          </div>-->
<!--          <div class="w-full bottom-0 border-t border-gray">-->
<!--            <button v-if="environment" @click="$emit('destroySession')" class="cursor-pointer text-red p-3 w-full text-left">-->
<!--              <i class="icon-logout text-xxl pr-sm align-middle" />-->
<!--              DESTRUIR SESIÓN-->
<!--            </button>-->
<!--            <button @click="$emit('closeSession');handleSidebar" class="cursor-pointer p-3 w-full text-left">-->
<!--              <i class="icon-logout text-xxl pr-sm align-middle" />-->
<!--              Cerrar sesión-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </aside>-->

<!--      <aside v-else class="bg-black bg-opacity-50 inset-0 z-80">-->

<!--        <transition name="fade">-->
<!--          <div @click="handleSidebar" class="fixed inset-0 bg-black z-80 bg-opacity-50" v-if="showSidebar" />-->
<!--        </transition>-->

<!--        <div class="flex flex-col w-12 fixed w-56 bg-white right-0 z-80 inset-y-0 transition-transform transform" :class="{-->
<!--          'translate-x-56': !showSidebar,-->
<!--          'translate-x-0': showSidebar-->
<!--        }">-->
<!--          <div class="p-normal text-left sidebar-i-container border-b border-gray">-->
<!--            <div class="flex items-center justify-between">-->
<!--              <div class="flex-none p-r-4">-->
<!--                <a-initials :name="profile?.name" :src="profile?.profile_picture"/>-->
<!--              </div>-->
<!--              <div v-if="user.isFoodie" class="flex-none text-lg ml-2" style="font-weight: 500">-->
<!--                <p class="border rounded-full px-4">{{ mappedBalance.replace('.', ',') }}</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <p class="text-lg font-regular pt-extra">{{ profile?.name }}</p>-->
<!--            <p v-if="profile?.ig_username" class="text-gray font-light">{{ '@' + profile?.ig_username}}</p>-->
<!--          </div>-->
<!--          <div class="border-b border-gray py-2">-->
<!--            <router-link @click="handleSidebar" :to="{ path: `/profile/${user.isRestaurant ? user.restaurant.id : user.foodie.id}`}" active-class="text-SFRed" class="p-3 block w-full text-left">-->
<!--              <i class="icon-profile text-xxl align-middle pr-sm" />-->
<!--              Mi perfil-->
<!--            </router-link>-->
<!--            <router-link @click="handleSidebar" :to="{ name: 'collaborations.index' }" active-class="text-SFRed" class="p-3 block w-full text-left">-->
<!--              <i class="icon-colaborate text-xxl align-middle pr-sm" />-->
<!--              Colaboraciones-->
<!--            </router-link>-->
<!--&lt;!&ndash;            <router-link @click="handleSidebar" v-if="user.isRestaurant" :to="{ name: 'collaborations.index' }" active-class="text-SFRed" class="p-3 block w-full text-left">&ndash;&gt;-->
<!--&lt;!&ndash;              <i class="icon-colaborate text-xxl align-middle pr-sm" />&ndash;&gt;-->
<!--&lt;!&ndash;              Colaboraciones&ndash;&gt;-->
<!--&lt;!&ndash;            </router-link>&ndash;&gt;-->
<!--            <router-link :to="{ name: 'historial.index' }" active-class="text-SFRed" class="p-3 block w-full text-left">-->
<!--              <i class="icon-countdown text-xxl align-middle pr-sm" />-->
<!--              Historial-->
<!--            </router-link>-->
<!--            &lt;!&ndash;        <div v-if="missingValues.userValidated">&ndash;&gt;-->
<!--            <button @click="sendInvitation" class="p-3 block w-full text-left">-->
<!--              <i class="icon-messageOpen text-xxl align-middle pr-sm" />-->
<!--              Enviar invitación-->
<!--            </button>-->
<!--            <button v-if="user.isFoodie" class="p-3 block w-full text-left" @click="goToSololink">-->
<!--              <i class="icon-group text-xxl align-middle pr-sm" />-->
<!--              Ir a sololink-->
<!--            </button>-->
<!--            <button v-if="user.isRestaurant" class="p-3 block w-full text-left" @click="handleSubscriptionLink">-->
<!--              <a-loader v-if="loading" class="h-6 w-6 inline-block mr-sm" :color="'text-gray-400'"/>-->
<!--              <i v-else class="icon-bookmark text-xxl align-middle pr-sm" />-->
<!--              <span class="inline-block">Suscripción</span>-->
<!--            </button>-->
<!--            <a v-if="user.isFoodie" href="https://www.youtube.com/playlist?list=PLdurWu2a_HN9BCVqnq7-h7HCqDD4zpTJ-" target="_blank" class="block p-3 text-left">-->
<!--              <i class="icon-userGroup text-xxl align-middle pr-sm inline-block" />-->
<!--              <span class="inline-block">Tutoriales</span>-->
<!--            </a>-->
<!--            <a v-if="user.isRestaurant" href="https://www.youtube.com/playlist?list=PLdurWu2a_HN-YaelObU6KgTS5APdRONKl" target="_blank" class="block p-3 text-left">-->
<!--              <i class="icon-userGroup text-xxl align-middle pr-sm inline-block" />-->
<!--              <span class="inline-block">Tutoriales</span>-->
<!--            </a>-->
<!--            <div v-if="stripeLinkError !== ''">-->
<!--              <p class="text-sm text-red-400">{{ stripeLinkError }}</p>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="flex-grow pt-small">-->
<!--            <router-link @click="handleSidebar" :to="{ name: 'Politics' }" active-class="text-SFRed" class="w-full block paddines_link cursor-pointer text-left">-->
<!--              Políticas de privacidad-->
<!--            </router-link>-->
<!--            <router-link @click="handleSidebar" :to="{ name: 'Terms' }" active-class="text-SFRed" class="w-full block paddines_link cursor-pointer text-left">-->
<!--              Condiciones de Uso y Contratación-->
<!--            </router-link>-->
<!--            <router-link @click="handleSidebar" :to="{ name: 'cookies-politics' }" active-class="text-SFRed" class="w-full block paddines_link cursor-pointer text-left">-->
<!--              Politica de Cookies-->
<!--            </router-link>-->
<!--          </div>-->
<!--          <div>-->
<!--            <a-logo-switch type="1" class="w-40 select-none lowercase inline-block"/>-->
<!--          </div>-->
<!--          <div class="w-full bottom-0 border-t border-gray">-->
<!--            <button v-if="environment" @click="destroySessionWithUser" class="cursor-pointer text-red p-3 w-full text-left">-->
<!--              <i class="icon-close text-xl pr-sm align-middle" />-->
<!--              DESTRUIR SESIÓN-->
<!--            </button>-->
<!--            <button @click="adviced = !adviced;handleSidebar" class="cursor-pointer p-3 w-full text-left">-->
<!--              <i class="icon-logout text-xxl pr-sm align-middle" />-->
<!--              Cerrar sesión-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </aside>-->
      <!-- SIDEBAR -->

      <!-- BODY -->
      <main v-if="loadingError">
        <h2>Ha ocurrido un error, por favor inténtelo nuevamente.</h2>
      </main>
      <main class="h-full lg:pt-14 max-w-5xl mx-auto" v-else-if="!loadingPage && !loadingUserType">
<!--        {{ getView }}-->
<!--        <component-->
<!--          v-if="getView !== 'default'"-->
<!--          :is="getView"-->
<!--          :restaurant="profile"-->
<!--          :profile="userValues"-->
<!--          :planValues="planStatus"-->
<!--          :user="profile"-->
<!--          :id="profile.id"-->
<!--          @updating-discover="fetchUser"-->
<!--          @refresh="getProfile"-->
<!--        >-->
<!--          <router-view />-->
<!--        </component>-->
<!--        <router-view v-else />-->
        <div v-if="haveMissingData && user.isRestaurant">
          <BillingAddress :restaurant="profile" />
        </div>
        <div v-else-if="stripeConditions">
          <stripe :profile="userValues" :planValues="planStatus"/>
        </div>
        <div v-else>
          <div class="fixed inset-x-0 max-w-5xl mx-auto bottom-0 bg-white transition-all overflow-hidden duration-500 z-60 h-full"
               v-if="!userValidated">
            <div v-if="!userRejected" class="fixed inset-x-0 max-w-5xl mx-auto bottom-0 bg-white transition-all overflow-hidden duration-500 z-60 h-full">
              <account v-if="!userDiscoverable && user?.isFoodie" :user="profile" @updating-discover="fetchUser" @refresh="getProfile"/>
              <waiting v-else :user="profile" :id="profile.id" @refresh="getProfile"/>
            </div>
            <failed v-else :user="user"/>
          </div>
          <div v-else>
            <router-view />
          </div>
        </div>
      </main>
      <!-- /BODY -->

      <nav class="fixed inset-x-0 container mx-auto bottom-0 lg:top-0 lg:bottom-auto border-t lg:border-t-0 max-w-5xl lg:border-b bg-white z-70">
        <div v-if="!profile?.ig_username && user?.isFoodie" class="grid grid-cols-4 gap-4 text-xxl border-gray font-light py-sm">
          <div class="text-gray">
          </div>
          <div class="text-gray">
          </div>
          <div class="text-gray">
          </div>
          <div class="text-gray cursor-pointer" @click="handleSidebar">
            <i class="icon-profile" />
          </div>
        </div>
        <div v-else class="grid grid-cols-4 gap-4 text-xxl border-gray font-light py-sm">
          <router-link :to="{ path: '/explore' }" active-class="text-dgray" class="text-gray">
            <i class="icon-search" />
          </router-link>
          <router-link v-if="user.isFoodie" :to="{ name: 'collaborations.index' }" active-class="text-dgray" :class="{
          'text-dgray': this.$route.path.split('/').filter($0 => $0)[0] === 'collaborations'
        }" class="text-gray">
            <i class="icon-colaborate"></i>
          </router-link>
          <router-link v-if="user.isRestaurant" :to="{ name: 'collaborations.index' }" active-class="text-dgray" :class="{
          'text-dgray': this.$route.path.split('/').filter($0 => $0)[0] === 'collaborations'
        }" class="text-gray">
            <i class="icon-colaborate"></i>
          </router-link>
          <router-link :to="{ path: '/notifications' }" active-class="text-dgray" :class="notifications.length > 0 ? 'text-red' : 'text-gray'">
            <i class="icon-bell">
            </i>
<!--            <div v-if="this.notifications" class="w-2 h-2 bg-red rounded-full custom-noti-position"></div>-->
          </router-link>
          <div class="text-gray cursor-pointer" @click="handleSidebar">
            <i class="icon-profile" />
          </div>
        </div>
      </nav>
    </div>
  </div>

  <a-alert :loading="loading" :show="adviced" @confirm="confirmLogout" @cancel="adviced = !adviced">
    <div class="text-left space-y-4">
      <h1 class="text-lg font-semibold text-center">¿Deseas cerrar la sesión?</h1>
    </div>

    <template #confirm>
      <a-loader color="text-primary" class="h-8 w-8" v-if="loading" />
      <template v-else>
        Aceptar
      </template>
    </template>
  </a-alert>

  <a-alert
    :loading="foodieInvitationModal.loadings.send || foodieInvitationModal.loadings.index || foodieInvitationModal.loadings.code"
    title="Código de invitación"
    hide-cancel
    :confirm-text="'Aceptar'"
    :show="foodieInvitationModal.show"
    :disabled-confirm="(foodieInvitationModal.loadings.send || foodieInvitationModal.loadings.index)"
    @confirm="initializeValues()">

    <div v-if="foodieInvitationModal.index">
      <div class="flex flex-col items-center">
        <p class="text-xl text-gray-600 pt-4">
          Cargando invitaciones...
        </p>
      </div>
    </div>
    <div v-else-if="foodieInvitationModal.code">
      <div class="space-y-4 flex flex-col">
        <div class="flex">
          <input type="text"
                 :value="foodieInvitationModal.invitations.code"
                 class="flex-auto"
                 disabled
          >
          <!--          <a-loader color="text-primary" class="h-8 w-8 flex-none py-2"/>-->
          <button @click="doCopy(registerRoute, foodieInvitationModal.invitations.code); generateCode()"
                  :disabled="foodieInvitationModal.loadings.code"
                  class="flex-none bg-primary rounded ml-2 cursor-pointer active:bg-gray disabled:bg-gray">
<!--            <a-loader color="text-primary" v-if="foodieInvitationModal.loadings.code" class="h-8 w-8 px-4 text-primary"/>-->
            <i class="icon-group text-xxl px-2 text-white align-middle"></i>
          </button>
        </div>
        <small v-if="copied" class="text-center"><p class="text-green-400">¡Mensaje copiado!</p></small>
        <small v-if="copiedError" class="text-center"><p class="text-red">¡Error, no se pudo copiar el mensaje!</p></small>

      </div>
      <small class="text-gray-400">
        {{ foodieInvitationModal.invitationStatus.max_consumable_invitations - foodieInvitationModal.invitationStatus.consumed_invitations_count }} invitaciones disponibles
      </small>

    </div>
    <div v-else-if="foodieInvitationModal.error">
      <div>
        <p>Lo sentimos, ya no tiene invitaciones disponibles</p>
      </div>
    </div>

    <template #confirm>
      <a-loader color="text-primary" class="h-8 w-8" v-if="foodieInvitationModal.loadings.send || foodieInvitationModal.loadings.index" />
    </template>
  </a-alert>

  <a-alert :show="invitationSent" @confirm="invitationSent = false" @cancel="invitationSent = false">
    <h1 class="text-lg font-semibold text-center">Se ha enviado la invitación con exito</h1>
  </a-alert>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { copyText } from 'vue3-clipboard'
import { ref } from 'vue'
import Waiting from '../messages/waiting'
import Failed from '../messages/failed'
import Account from '../messages/account'
import Stripe from '../messages/stripe/stripe'
import BillingAddress from '../messages/address/index'
import Sidebar from './sidebar'

export default {
  components: {
    Waiting,
    Failed,
    Account,
    Stripe,
    BillingAddress,
    Sidebar
  },
  setup () {
    const copied = ref(false)

    const doCopy = (route, invitationCode) => {
      const invitationRoute = 'Te han invitado a Solofoodies, tu Código es: ' + invitationCode + '\n' + window.location.origin + route + '?invitation_code=' + invitationCode
      copyText(invitationRoute.toString(), undefined, (error, event) => {
        if (error) {
          copied.value = false
        } else {
          copied.value = true
        }
      })
    }

    return { doCopy, copied }
  },
  data () {
    return {
      loadingPage: true,
      code: null,
      profile: [],
      notifications: [],
      planStatus: {},
      form: {
        email: '',
        password: ''
      },
      foodieInvitationModal: {
        show: false,
        email: '',
        loadings: {
          index: false,
          send: false,
          error: false,
          code: false
        },
        invitations: {},
        index: false,
        code: false,
        error: false,
        invitationStatus: {}
      },
      invitationSent: false,
      haveBack: false,
      sidebar: true,
      responsiveMenu: false,
      showSidebar: false,
      adviced: false,
      loading: false,
      loadingValidation: false,
      loadingUserType: false,
      loadingLink: false,
      notification: [],
      markNotification: false,
      query: {
        read: false
      },
      copiedMessage: false,
      copiedError: false,
      aux: 1,
      baseUrl: '',
      registerRoute: '/register/invitation',
      environment: false,
      userValidated: false,
      userDiscoverable: false,
      userRejected: false,
      current_plan: false,
      haveMissingData: false,
      stripePanel: '',
      stripeLinkError: '',
      billingAddress: {},
      isAllStars: false,
      loadingError: false,
      userValues: {}
    }
  },
  computed: {
    ...mapGetters(['lang', 'routes']),
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated',
      error: 'error'
    }),
    ...mapState({
      user: ({ session }) => session.user,
      foodie: ({ foodies }) => foodies.current
    }),
    mappedBalance () {
      return `${(this.profile.balance_e2 / 100).toFixed(2)}€`
    },
    mappedPermissions () {
      if (this.user.isRestaurant) {
        if (!this.userValidated || this.userRejected) {
          return true
        }
        if (this.haveMissingData) {
          return true
        }
        if (this.profile.skip_trial) {
          return false
        }
        return false
      }
      if (this.user.isFoodie) {
        if (!this.userValidated || !this.userDiscoverable || this.userRejected) {
          return true
        }
        if (!this.profile.ig_username) {
          return true
        }
        return false
      }
      return false
    },
    getView () {
      if (this.haveMissingData && this.user.isRestaurant) {
        return 'billing-address'
      } else if (this.stripeConditions) {
        return 'stripe'
      } else if (!this.userValidated) {
        if (!this.userRejected) {
          if (!this.userDiscoverable && this.user.isFoodie) {
            return 'account'
          } else {
            return 'waiting'
          }
        } else {
          return 'failed'
        }
      } else {
        return 'default'
      }
      // <div v-if="haveMissingData && user.isRestaurant">
      //             <BillingAddress :restaurant="profile" />
      //           </div>
      //           <div v-else-if="stripeConditions">
      //             <stripe :profile="userValues" :planValues="planStatus"/>
      //           </div>
      //           <div v-else>
      //             <div class="fixed inset-x-0 max-w-5xl mx-auto bottom-0 bg-white transition-all overflow-hidden duration-500 z-60 h-full"-->
      //                  v-if="!userValidated">
      //               <div v-if="!userRejected" class="fixed inset-x-0 max-w-5xl mx-auto bottom-0 bg-white transition-all overflow-hidden duration-500 z-60 h-full">-->
      //                 <account v-if="!userDiscoverable && user?.isFoodie" :user="profile" @updating-discover="fetchUser" @refresh="getProfile"/>-->
      //                 <waiting v-else :user="profile" :id="profile.id" @refresh="getProfile"/>
      //               </div>
      //               <failed v-else :user="user"/>
      //             </div>
      //             <div v-else>
      //               <router-view />
      //             </div>
      //           </div>
    },
    // availableInvitations () {
    //   console.log(this.foodieInvitationModal.invitations.filter(invitation => invitation.status === 'pending'))
    //   return this.foodieInvitationModal.invitations.filter(invitation => invitation.status === 'pending')
    // },
    stripeConditions () {
      // console.log(!this.current_plan,
      //   this.user.isRestaurant,
      //   !this.isAllStars,
      //   this.planStatus?.status !== 'trailing',
      //   this.current_plan && this.planStatus?.status === 'past_due')
      //
      // const a = !this.current_plan &&
      //   this.user.isRestaurant &&
      //   !this.isAllStars &&
      //   (this.planStatus?.status !== 'trailing' || (this.current_plan && this.planStatus?.status === 'past_due'))
      if (this.user.isRestaurant) {
        if (!this.isAllStars) {
          if (!this.current_plan) {
            return true
          } else if (this.planStatus?.status === 'past_due') {
            return true
          }
        }
      }
      return false
    }
  },
  methods: {
    ...mapActions({
      logout: 'session/logout',
      clear: 'clear',
      getFoodie: 'foodies/show'
      // notifications: 'notifications/index'
    }),
    getPlanState () {
      this.$repository.stripe
        .state()
        .then(({ data }) => {
          this.planStatus = data
        })
        .catch(() => {
          this.planStatus = {}
        })
    },
    handleSubscriptionLink () {
      if (this.user.isRestaurant) {
        this.loadingLink = true
        this.stripeLinkError = ''
        const actualUrl = window.location.origin + '/explore'
        // const return_url = process.env.NODE_ENV === 'production' ? `${process.env.}`
        this.$repository.stripe.stripeBillingPortal({ return_url: actualUrl }, { cancelable: false })
          .then(({ data: response }) => {
            this.loadingLink = false
            this.stripePanel = response?.billing_portal_url
            window.location.href = this.stripePanel
          })
          .catch(() => {
            this.stripeLinkError = 'Ha ocurrido un error al intentar ingresar al panel, intentelo nuevamente.'
          })
      }
    },
    goToSololink () {
      this.$router.push({ name: 'sololink.edit' })
      this.showSidebar = false
      // .then(() => {
      //   copyText(location.href, undefined, () => {})
      // })
    },
    getProfile () {
      this.loadingUserType = true
      this.loadingValidation = true
      if (this.user.isRestaurant) {
        this.$repository.restaurants
          .show(this.user.restaurant.id, { with: 'billingAddress' }, { cancelable: false })
          .then((response) => {
            this.profile = response?.data
            this.fetchUser()
          })
          .catch((error) => {
            console.log(error)
            this.loadingError = true
          })
          .finally(() => (this.loadingUserType = false))
      } else {
        this.getFoodie(this.user.foodie.id)
          .then((resp) => {
            this.profile = resp
            this.fetchUser()
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => (this.loadingUserType = false))
        // this.$repository.foodies
        //   .show(this.user.foodie.id, {}, { cancelable: false })
        //   .then((response) => {
        //     this.profile = response?.data
        //   })
        //   .catch((error) => {
        //     console.log(error)
        //   })
        //   .finally(() => (this.loadingUserType = false))
      }
    },
    fetchUser () {
      // test
      this.$repository.users
        .show(this.user.id, { with: 'restaurant.billingAddress' }, { cancelable: false })
        .then(({ data }) => {
          this.userValues = data
          this.userValidated = !!data?.ig_validated_at
          this.userDiscoverable = data?.ig_discoverable
          this.userRejected = !!data?.rejected
          this.current_plan = !!data?.current_plan
          this.billingAddress = data.billing_address
          this.haveMissingData = !data?.restaurant?.billing_address || !data?.restaurant?.cif || !data?.ig_username || !data?.restaurant?.phone
          this.isAllStars = this.profile?.settings?.skip_subscription
          if (this.user.isRestaurant) {
            this.getPlanState()
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => (this.loadingPage = false))
    },
    handleRedirect () {
      this.showSidebar = false
      if (this.user.isRestaurant) {
        this.$router.replace({ path: `/profile/${this.user.restaurant.id}` })
      } else {
        this.$router.replace({ path: `/profile/${this.user.foodie.id}` })
      }
    },
    destroySessionWithUser () {
      if (confirm('Estas a punto de eliminar este usuario completamente.\n¿Desea continuar?')) {
        this.$repository.users
          .dangerousDestroy(this.user.id)
          .then(this.confirmLogout)
      }
    },
    handleSidebar () {
      return (this.showSidebar = !this.showSidebar)
    },
    confirmLogout () {
      this.loading = !this.loading
      this.logout()
        .finally(() => {
          this.$router.push({ name: 'Login' })
        })
    },
    markAsSent (codeId) {
      this.$repository.invitations
        .send(codeId)
      return true
    },
    fetchNotifications () {
      this.$repository.notifications
        .index({ read: false }, { cancelable: false })
        .then(({ data: response }) => {
          this.notifications = response?.data
        })
    },
    sendInvitation () {
      this.copied = false
      this.foodieInvitationModal.show = true
      this.foodieInvitationModal.loadings.index = true
      this.foodieInvitationModal.index = true
      this.showSidebar = false
      this.copiedMessage = false
      this.copiedError = false
      this.$repository.invitations
        .getInvitations(this.profile.user_id)
        .then((response) => {
          this.foodieInvitationModal.invitationStatus = response.data
          this.generateCode()
          this.foodieInvitationModal.loadings.index = false
          this.foodieInvitationModal.index = false
        })
        .catch(() => {
          this.foodieInvitationModal.loadings.index = false
          this.foodieInvitationModal.index = false
          this.foodieInvitationModal.code = false
          this.foodieInvitationModal.error = true
        })
    },
    initializeValues () {
      this.foodieInvitationModal.show = false
      this.foodieInvitationModal.loadings.index = false
      this.foodieInvitationModal.loadings.send = false
      this.foodieInvitationModal.loadings.error = false
    },
    generateCode () {
      this.foodieInvitationModal.code = true
      this.foodieInvitationModal.loadings.code = true
      this.copiedMessage = false
      this.$repository.invitations
        .generate({ cancelable: false })
        .then((response) => {
          this.foodieInvitationModal.invitations = response.data
          this.foodieInvitationModal.loadings.code = false
        })
        .catch(() => {
          this.foodieInvitationModal.error = true
          this.foodieInvitationModal.code = false
          this.copiedError = true
          this.copiedMessage = false
        })
    }
  },
  watch: {
    showSidebar (val) {
      document.body.style.overflow = val === true ? 'hidden' : 'auto'
    }
  },
  mounted () {
    this.loadingPage = true

    if (this.isAuthenticated) {
      this.copiedMessage = false
      this.getProfile()
      this.fetchNotifications()

      // if (this.user.isRestaurant) {
      //   const actualUrl = window.location.origin + '/explore'
      //   // const return_url = process.env.NODE_ENV === 'production' ? `${process.env.}`
      //   this.$repository.stripe.stripeBillingPortal({ return_url: actualUrl }, { cancelable: false })
      //     .then(({ data: response }) => {
      //       this.stripePanel = response?.billing_portal_url
      //     })
      // }

      window.setInterval(() => {
        if (this.isAuthenticated) {
          this.fetchNotifications()
        }
      }, 300000)
    }
    this.showSidebar = false
    document.body.style.overflow = 'auto'
    this.baseUrl = window.location.origin
    this.environment = process.env.VUE_APP_NODE_ENV === 'dev'
  }
}
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition:  opacity 0.3s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.sidebar-i-container{
  padding-bottom: 18px !important;
}
.logout-container{
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.responsive-pb-16{
  @media (min-width: 1000px) {
    padding-top: 56px;
  }
}
//.responsiveBottom{
//  max-width: 64rem;
//  margin:0 auto;
//  @media (min-width: 1000px) {
//    border-bottom-width: 1px;
//    top:0;
//  }
//  @media (max-width: 999px) {
//    border-top-width: 1px;
//    bottom:0;
//  }
//}
  .paddines_link{
    padding: 0.5rem 0.75rem;
    line-height:1.2;
  }
  .custom-noti-position{
    margin-top: -43%;
    margin-left: 60%;
    display: block;
  }
</style>
