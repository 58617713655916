<template>
  <div v-if="variation">
    <ul class="grid gap-2 grid-cols-2 md:grid-cols-3 grid-rows-1 xl:grid-cols-4 w-full mb-2">
      <template v-for="(item, key) in source" :key="key">
        <slot v-bind="{item, index: key}">
          <li @click="$emit('item-click', item, key)">
            {{ typeof label === 'function' ? label(item) :  item[label] }}
          </li>
        </slot>
      </template>
    </ul>
    <span v-if="loading" class="text-gray">
      <slot name="loading">
        <div class="flex items-center justify-center space-x-4">
          <a-loader color="text-gray" class="h-10 w-10" />
          <span>
            Buscando nuevo contenido...
          </span>
        </div>
      </slot>
    </span>
    <span v-else-if="source.length && !loading" class="text-gray">
      <slot name="limit-reached">
        Has llegado al final
      </slot>
    </span>
    <span v-else-if="!source.length && !loading" class="text-gray">
      <slot name="empty">
        No hay contenido que mostrar
      </slot>
    </span>
  </div>
  <ul v-else class="w-full">
    <template v-for="(item, key) in source" :key="key">
      <slot v-bind="{item, index: key}">
        <li @click="$emit('item-click', item, key)">
          {{ typeof label === 'function' ? label(item) :  item[label] }}
        </li>
      </slot>
    </template>

    <li class="p-2 text-center">
      <span v-if="loading" class="text-gray">
        <slot name="loading">
          <div class="flex items-center justify-center space-x-4">
            <a-loader color="text-gray" class="h-10 w-10" />
            <span>
              Buscando nuevo contenido...
            </span>
          </div>
        </slot>
      </span>
      <span v-else-if="source.length && !loading" class="text-gray">
        <slot name="limit-reached">
          Has llegado al final
        </slot>
      </span>
      <span v-else-if="!source.length && !loading" class="text-gray">
        <slot name="empty">
          No hay contenido que mostrar
        </slot>
      </span>
    </li>
  </ul>
</template>

<script>
import { toRef, onMounted, onUnmounted } from 'vue'
export default {
  props: {
    source: { required: false, type: Array, default: () => [] },
    label: { required: false, type: [Function, String], default: () => '' },
    loading: { required: false, type: Boolean, default: false },
    offset: { required: false, type: Number, default: 180 },
    shouldEmitBottomReached: { required: false, type: Boolean, default: false },
    variation: { required: false, type: Boolean, default: false }
  },
  emits: ['bottom-reached', 'scroll-change'],
  setup (props, { emit }) {
    const loading = toRef(props, 'loading')
    const shouldEmitBottomReached = toRef(props, 'shouldEmitBottomReached')

    const scrollBottomHandler = event => {
      const current = event.target.scrollingElement.scrollTop + event.target.scrollingElement.clientHeight
      const height = event.target.scrollingElement.scrollHeight

      emit('scroll-change', { current, height })

      if (current >= height) {
        if (shouldEmitBottomReached.value && !loading.value) {
          emit('bottom-reached')
        }
      }
    }

    onMounted(() => {
      window.addEventListener('scroll', scrollBottomHandler)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', scrollBottomHandler)
    })

    return {}
  }
}
</script>
