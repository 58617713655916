<template>
  <div class="space-y-8 flex flex-col text-gray_light">
    <Logo class="self-center text-left mt-8 h-16 cursor-pointer" />

    <!--    <div v-if="latitude || longitude" >-->
<!--      <p class="mx-24 font-light">{{ latitude + '/' + longitude }}</p>-->
<!--    </div>-->
<!--    <p v-if="!latitude || !longitude" class="mx-24 font-light">Es necesario activar la geolocalizacion para mejorar la experiencia de usuario</p>-->
    <p class="mx-4 font-bold text-onboarding_gray">Verifica la dirección de tu restaurante</p>

    <div class="space-y-3 w-full">
      <div class="flex flex-col w-full px-4 text-black">
        <input
          type="text"
          :value="address"
          placeholder="Dirección *"
          @input="$emit('update:address', $event.target.value)"
          class="border rounded-md px-4 py-2 w-full">
      </div>
      <div class="flex w-full px-4 text-black">
        <input
          type="text"
          :value="city"
          placeholder="Ciudad"
          @input="$emit('update:city', $event.target.value)"
          class="border rounded-md px-4 py-2 mr-2 flex-1">
<!--        <input-->
<!--          type="text"-->
<!--          value="Madrid"-->
<!--          disabled-->
<!--          placeholder="Estado/Provincia/Región"-->
<!--          @input="$emit('update:state', $event.target.value)"-->
<!--          class="border rounded-md px-4 py-2 ml-2 flex-1 disabled:bg-gray_light">-->
        <select id="companionSelectorSS" class="flex-1 border ml-2 p-1 rounded px-2" v-model="cstate" @change="this.$emit('update:state', $event.target.value)">
          <option value="Madrid">Madrid</option>
          <option value="Barcelona">Barcelona</option>
          <option value="Sevilla">Sevilla</option>
          <option value="Bilbao">Bilbao</option>
          <option value="Málaga">Málaga</option>
        </select>
      </div>
      <div class="flex w-full px-4 text-black">
        <input
          type="number"
          :value="zip_code"
          placeholder="Código postal"
          @input="$emit('update:zip_code', $event.target.value)"
          class="border rounded-md px-4 py-2 mr-2 flex-1">
        <input
          disabled
          type="text"
          value="España"
          class="border rounded-md px-4 py-2 ml-2 flex-1 disabled:bg-gray_light">
      </div>
    </div>
    <div v-if="confirmation" class="text-red text-left">
      Por favor Agregue la dirección
    </div>
  </div>
</template>

<script>
import Logo from '@/components/logos/logo-1'
// TODO ACOMODAR EL LOADER PERPETUO CUANDO NO SE HA INGRESADO LA DIRECCION
export default {
  components: {
    Logo
  },
  props: {
    address: { required: false, type: String, default: '' },
    city: { required: false, type: String, default: '' },
    state: { required: false, type: String, default: '' },
    zip_code: { required: false, type: String, default: '' },
    confirmation: { required: false, type: Boolean, default: false }
  },
  data () {
    return {
      cstate: ''
    }
  },
  mounted () {
    this.cstate = this.state
  },
  watch: {
    state (value) {
      console.log(value)
    }
  }
}
</script>
