import * as Errors from '../assets/errors/es'

const useErrors = (errorCode) => {
  // console.log(Errors[errorCode])
  // console.log(Errors[errorCode])
  if (Errors[errorCode]?.message) {
    return Errors[errorCode]
  } else {
    return 'Ha ocurrido un error inesperado: ' + errorCode
  }
}

export default useErrors
