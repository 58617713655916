<template>
  <div class="space-y-6 flex flex-col text-gray_light w-full">
    <Logo class="self-center text-left mt-6 h-16 cursor-pointer" />

    <div v-if="stripe.loading">
      <a-loader class="w-14 h-14 block mx-auto" :color="'text-gray-300'"/>
    </div>

    <div v-else class="wrapper block mx-auto bg-white mx-4 sm:w-6/12">
      <form id="payment-form" class="m-4" @submit.prevent="handleSubscription()">
        <div id="payment-element" class="text-white">
          <!-- Elements will create form elements here -->
        </div>
        <div v-if="stripe.errors" id="error-message mt-4"><h2 class="my-4 text-red-400">{{ stripe?.errors?.message }}</h2></div>
        <button
          :disabled="stripe.loadingSubscription || stripe.performingSubscription"
          id="submit"
          class="bg-primary text-white py-4 w-full px-6 rounded-full mt-10 disabled:bg-gray-300">
          <span v-if="stripe.loadingSubscription || stripe.performingSubscription">
            <a-loader class="h-6 w-6 block mx-auto" :color="'text-white'"/>
          </span>
          <span v-else class="block h-6 mx-auto">
            Suscribirse
          </span>
        </button>
<!--        <div v-if="stripe.errors" id="error-message mt-4">{{ stripe?.errors?.message }}</div>-->
      </form>
    </div>
  </div>
  <a-alert
    :show="stripe.modal"
    :disabled-confirm="stripe.loadingSubscription || stripe.performingSubscription"
    @confirm="confirmSubscription"
    @cancel="stripe.modal = false"
    :loader="stripe.performingSubscription || stripe.loadingSubscription"
    :loading="stripe.performingSubscription || stripe.loadingSubscription"
    :loaderStyle="'h-6 w-6 text-gray-300'"
  >
    <p class="text-center text-lg"><b>¿Desea continuar con la suscripción?</b></p>
  </a-alert>
</template>

<script>
import { onMounted, ref } from 'vue'
// import { StripeCheckout } from '@vue-stripe/vue-stripe'
import Logo from '@/components/logos/logo-1'
import StripeJs from '@/sdk/stripe'

export default {
  name: 'step-checkout',
  props: {
    plan: { required: false, type: Object }
  },
  components: {
    // StripeCheckout,
    Logo
  },
  setup (props) {
    const loading = ref(false)
    const modal = ref(false)
    const publishableKey = ref('')
    const lineItems = ref([])
    const stripe = ref(new StripeJs(props.plan))
    const aux = ref(false)

    // const submit = () => {
    //   this.$refs.checkoutRef.redirectToCheckout()
    // }

    // const loadingEvent = (event) => {
    //   this.loading = event.loading
    // }

    const initializeStripe = () => {
      return stripe.value.setupStripe()
    }

    const handleSubscription = async () => {
      if (!aux.value || stripe.value.errors) {
        await stripe.value.submitSubscription(stripe.value.elements)
          .then(() => {
            if (!stripe.value.errors) {
              confirmSubscription()
            }
          })
      }
    }

    const confirmSubscription = () => {
      return stripe.value.performSubscription(stripe.value.setupIntent.payment_method)
    }

    onMounted(() => {
      return initializeStripe()
    })

    return {
      loading,
      modal,
      publishableKey,
      lineItems,
      stripe,
      handleSubscription,
      // submit,
      confirmSubscription
    }
  }
}
</script>

<style scoped>
label.p-FieldLabel{
  color: #fff !important;
}
#payment-form{
  margin-bottom: 145px;
}
</style>
