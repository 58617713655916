<template>
  <div class="space-y-6 flex flex-col text-gray_light">
    <Logo class="self-center text-left mt-6 w-64 h-16 cursor-pointer" />
    <div v-if="loading">
      <a-loader class="w-14 h-14 block mx-auto" :color="'text-gray-300'"/>
    </div>
    <div v-else>

      <div v-if="profile.first_login" class="mx-4">
        <div class="block text-center text-onboarding_gray">
          <h2 class="md:text-xl sm:text-md">Te regalamos 7 días seleccionando tu plan. Podrás cancelar sin coste durante el periodo gratuito de prueba.</h2>
        </div>
      </div>
      <div v-else class="mx-4">
        <div v-if="planValues?.trial_days_left === 0" class="block text-center text-onboarding_gray">
          <h2 class="md:text-xl sm:text-md">Selecciona el plan ideal para ti.</h2>
        </div>
        <div v-else class="block text-center text-onboarding_gray">
          <h2 class="md:text-xl sm:text-md">Extiende el periodo gratuito por 7 días seleccionando tu plan. Podrás cancelar sin coste durante este período.</h2>
        </div>
      </div>

<!--      <div class="block text-center text-onboarding_gray my-4">-->
<!--        <h2 class="inline-block w-auto text-SFRed text-xxl">Solo <span class="">{{ mappedPrice }}</span></h2>-->
<!--        <div class="h-6">-->
<!--          <p v-if="selectedPlan.duration_in_days === 365" class="text-SFRed md:text-lg sm:text-md">*contrato anual</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="text-center custom-max-w mx-auto">-->
<!--        <div class="space-x-2">-->
<!--        </div>-->
<!--      </div>-->

      <div class="wrapper block mx-auto text-onboarding_gray mx-4 sm:w-6/12">
        <div v-for="plan in plans" :key="plan" class="m-4">
          <button
            @click="selectPlan(plan.visible_prices[0])"
            class="flex option option-1 border border-onboarding_gray p-normal rounded-md w-full transition-all"
            :class="{
                'selected-plan': selectedPlan === plan.visible_prices[0]
            }"
          >
            <span class="flex-auto">
              <span class="title block text-xl font-bold">{{ plan.visible_prices[0].name }}</span>
              <span class="text block text-lg mb-4">{{ plan.visible_prices[0].description }}</span>
              <span
                v-for="description in formattedDescription(plan.description)"
                :key="description" class="text block text-left mb-2">
                {{ description }}
              </span>
            </span>
            <span class="flex-none self-center ml-2">
              <button
                class="rounded-full border px-4 py-small text-white bg-onboarding_gray font-medium transition-all"
                :class="{
                    'selected-button': selectedPlan === plan.visible_prices[0]
                }"
              >
                {{ selectedPlan === plan.visible_prices[0] ? 'Elegido' : 'Elegir'}}
              </button>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch, computed } from 'vue'
import Logo from '@/components/logos/logo-1'
import { useRepository } from '../../../hooks'
// import moment from 'moment'

export default {
  name: 'step-plans',
  props: {
    plan: { required: false, type: Object },
    profile: { required: false, type: Object },
    planValues: { required: false, type: Object }
  },
  components: {
    Logo
  },
  emits: ['update:plan'],
  setup (props, { emit }) {
    const loading = ref(false)
    const picked = ref('2')
    const stripe = useRepository(({ stripe }) => stripe)
    const plans = ref([])
    const description = ref('')
    const selectedPlan = ref({})

    const getPlans = () => {
      loading.value = true
      stripe.plans()
        .actives()
        .then(({ data: response }) => {
          plans.value = response.map((plan) => {
            plan.visible_prices = plan.visible_prices.filter((value) => (value.billing_interval === 'monthly'))
            return plan
          })
          description.value = plans.value[0].visible_prices[0].description
          selectedPlan.value = plans.value[0].visible_prices[0]
        })
        .finally(() => {
          loading.value = false
        })
    }

    const mappedPrice = computed(() => {
      if (selectedPlan.value.duration_in_days === 365) {
        const price = (selectedPlan.value.price_e2 / 100) / 12
        return price.toFixed(2) + '€/mes'
      }
      // selectedPlan.value
      return selectedPlan.value.description
    })

    const hasTrialExpired = computed(() => {
      // const dateValidation = moment(props.profile.trial_end_at).isAfter(moment())
      return false
    })

    const selectPlan = (plan) => {
      selectedPlan.value = plan
      // emit('update:plan', selectedPlan.value)
    }

    const formattedDescription = (planDescription) => {
      const dd = planDescription.split('\n')
      return dd.flatMap(value => {
        return value !== '' ? value : []
      })
    }

    watch(() => selectedPlan.value, (value) => {
      emit('update:plan', value)
    })

    onMounted(() => {
      getPlans()
    })

    return {
      picked,
      loading,
      plans,
      selectedPlan,
      description,
      mappedPrice,
      hasTrialExpired,
      selectPlan,
      formattedDescription
    }
  }
}
</script>

<style scoped>

.selected-plan {
  @apply bg-onboarding_gray text-white
}
.selected-button {
  background-color: #fff;
  font-weight: 500;
  color: #1D252C;
  border: none;
}
.wrapper .option {
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

input[type="radio"] {
  display: none;
}

#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2 {
  /*border-color: #cf1a1a;*/
  background-color: #fff;
  color: #1D252C;
}

#option-1:checked:checked ~ .option-1 button,
#option-2:checked:checked ~ .option-2 button {
  background-color: #1D252C;
  font-weight: 500;
  color: #fff;
  border: none;
}

</style>
