<template>
  <transition name="fade">
    <div class="fixed bg-black inset-0 bg-opacity-50 z-20" @click="isSearching = !isSearching" :class="{'backdrop-filter backdrop-blur-sm': backdrop}" v-if="isSearching" />
  </transition>

  <div class="flex max-w-5xl h-14 top-navbar-dims container items-center pr-4 fixed top-0 uppercase text-gray justify-between z-30" :class="{ 'space-x-2': isSearching, 'bg-white border-b': !isSearching }">
    <div class="flex items-center text-left pl-4 flex-grow z-30" :class="{ 'mt-2': isSearching }">
      <template v-if="!isSearching">
        <button v-if="isBack" @click="$router.back" class="p-4 -mx-4 select-none flex space-x-2 text-gray-900 items-center">
          <i class="icon-arrowLeft text-gray-600" v-if="isBack" />
          <slot v-bind={dismiss} />
        </button>
        <div class="flex space-x-2 items-center" v-else>
          <slot v-bind={dismiss} />
        </div>
      </template>
      <input
        ref="search"
        v-else
        autofocus
        v-debounce:[debounce]="search => $emit('search', search)"
        :placeholder="placeholder"
        class="border text-lg bg-opacity-75 border-gray-300 bg-white text-black focus:outline-none px-4 w-full py-2 rounded-md">
    </div>
    <div v-if="!isSearching">
      <div class="flex items-center justify-center z-30" v-if="showMulti">
        <button class="text-white px-2" @click="multiSelection(true)">
          <slot name="multi">
            <i class="icon-group inline-block py-4 text-xl text-left cursor-pointer" :class="{
              'text-gray-900': !multi,
              'text-primary': multi
            }" />
          </slot>
        </button>
      </div>
    </div>
    <div class="flex items-center justify-center z-30" v-if="!hideSearch">
      <slot name="search-button" v-if="!isSearching" v-bind="{ toggle: () => (isSearching = !isSearching)}">
        <button class="text-white px-2 -mr-4" @click="(isSearching = !isSearching) && multiSelection(false)">
          <i class="icon-search text-primary inline-block p-4 text-xl text-left cursor-pointer custom-font-bold" />
        </button>
      </slot>
      <button v-else class="text-white pr-2 py-2 mt-2 -mr-2" @click="isSearching = !isSearching">
        <slot name="cancel">
          Cancelar
        </slot>
      </button>
    </div>
  </div>

  <div class="fixed inset-x-0 top-16 z-20 px-4" v-if="isSearching">
    <div class="flex flex-col space-y-4">
      <slot name="results" v-bind={dismiss} />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from 'vue'
export default {
  props: {
    backdrop: { required: false, type: Boolean, default: false },
    debounce: { required: false, type: [Number, String], default: 400 },
    placeholder: { required: false, type: String, default: 'Buscar...' },
    hideSearch: { required: false, type: Boolean, default: false },
    showMulti: { required: false, type: Boolean, default: false },
    isBack: { required: false, type: Boolean, default: false }
  },
  setup (_, { emit }) {
    const isSearching = ref(false)
    const activeMulti = ref(false)
    const multi = ref(false)
    const search = ref(null)

    const onEscape = (e) => {
      if (e.key === 'Esc' || e.key === 'Escape') {
        isSearching.value = false
      }
    }

    onMounted(() => {
      document.addEventListener('keydown', onEscape)
    })

    onUnmounted(() => {
      document.removeEventListener('keydown', onEscape)
    })

    const dismiss = () => {
      isSearching.value = !isSearching.value
    }

    watch(isSearching, () => search.value?.focus?.(), {
      flush: 'post'
    })

    const multiSelection = (value) => {
      if (value) {
        multi.value = !multi.value
      } else {
        multi.value = false
      }

      emit('multiSelection', multi.value, isSearching.value)
    }

    return {
      isSearching,
      activeMulti,
      multiSelection,
      dismiss,
      multi,
      search
    }
  },
  emits: ['search', 'multiSelection']
}
</script>

<style scoped>

@screen lg {
    .top-navbar-dims {
      top: 57px;
    }
}
.custom-font-bold{
  font-weight: bold !important;
}
</style>
